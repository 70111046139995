import React from 'react'
import styles from './Certificates.module.scss';
import Aside from '../../Components/Aside/Aside';
import Certificate from './components/Certificate/Certificate';
import News from '../../Components/News/News';

const Certificates = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <Aside />
        <Certificate />
      </div>
      <News />
    </>
  )
}


export default Certificates;