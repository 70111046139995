import React from 'react'
import { useOutletContext } from 'react-router-dom';
import News from '../../Components/News/News';
import Promo from './components/Promo/Promo';
import Partners from './components/Partners/Partners'
import Banner from './components/Banner/Banner';
import Warning from './components/Warning/Warning';
import How from './components/How/How';
import Benefits from './components/Benefits/Benefits';
import Server from './components/Server/Server';
import Advantages from './components/Advantages/Advantages';
import Token from './components/Token/Token';
import Registration from './components/Registration/Registration';


const MainPage = ({lang}) => {
  const {user} = useOutletContext()
  return (
    <>
      {
        user && <News />
      }
      <Promo />
      <Partners lang={lang} />
      <Banner />
      <Warning />
      <How />
      <Benefits />
      <Server />
      <Advantages />
      <Token />
      <Registration />
    </>
  )
}


export default MainPage;