import React from "react";
import styles from "./SuccessCoupon.module.scss";
import CloseIcon from "../../../../img/imageComponents/CloseIcon";
import { useTranslation } from 'react-i18next';
import CheckoutIcon from "../../../../img/imageComponents/CheckoutIcon";

const SuccessCoupon = ({ closeAction }) => {
  const { t } = useTranslation(); 
  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        <button
          className={styles.modal__close}
          onClick={() => closeAction(false)}
        >
          <CloseIcon />
        </button>
        <CheckoutIcon 
          color="#30A03B" 
          width="50px"
          height="50px"
        />
        <div className={styles.modal__title}>
          {t("Success!")}
        </div>
        <div className={styles.modal__descr}>
          {t("You have a free certificate!")}
        </div>
        <button
          className={styles.modal__button}
          onClick={() => closeAction(false)}
        >
          {t("Close")}
        </button>
      </div>
    </div>
  );
};

export default SuccessCoupon;
