import { useEffect } from 'react';
import styles from './BenefitsBlock.module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const BenefitsBlock = ({ icon, description }) => {
  const { t } = useTranslation(); 

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className={styles.benefit} data-aos="fade-up" data-aos-duration="1000">
      <div className={styles.benefit__icon}>
        <img src={icon} alt="icon" />
      </div>
      <div className={styles.benefit__descr}>
        {t(description)}
      </div>
    </div>
  )
}

export default BenefitsBlock;