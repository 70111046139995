import React from 'react'
import styles from './Error.module.scss'
import errorIcon from '../../img/error.svg'

const Error = () => {
  return (
    <section className={styles.error}>
      <img src={errorIcon} alt="error" />
      <div className={styles.error__title}>Ooops… Error!</div>
      <button className={styles.error__btn}>Back</button>
    </section>
  )
}

export default Error;