import React, { memo } from "react";

const Document = memo(({ color = "#000000", width = "20", height="20" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33334 5.83329V3.33329C3.33334 2.89127 3.50894 2.46734 3.8215 2.15478C4.13406 1.84222 4.55798 1.66663 5.00001 1.66663H12.0833L16.6667 6.24996V16.6666C16.6667 17.1087 16.4911 17.5326 16.1785 17.8451C15.866 18.1577 15.442 18.3333 15 18.3333H10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 1.66663V6.66663H16.6667"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16666 14.1666C4.49496 14.1666 4.82005 14.102 5.12337 13.9763C5.42668 13.8507 5.70228 13.6665 5.93442 13.4344C6.16657 13.2022 6.35072 12.9266 6.47636 12.6233C6.60199 12.32 6.66666 11.9949 6.66666 11.6666C6.66666 11.3383 6.60199 11.0132 6.47636 10.7099C6.35072 10.4066 6.16657 10.131 5.93442 9.89886C5.70228 9.66671 5.42668 9.48256 5.12337 9.35693C4.82005 9.23129 4.49496 9.16663 4.16666 9.16663C3.50362 9.16663 2.86773 9.43002 2.39889 9.89886C1.93005 10.3677 1.66666 11.0036 1.66666 11.6666C1.66666 12.3297 1.93005 12.9656 2.39889 13.4344C2.86773 13.9032 3.50362 14.1666 4.16666 14.1666Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83332 13.75L6.66666 18.3333L4.16666 17.5L1.66666 18.3333L2.49999 13.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default Document;
