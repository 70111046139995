import styles from './Promo.module.scss';
import '../../../../common.css';
import promo from '../../../../img/promo.png';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation(); 

  return (
    <section className={styles.promo}>
      <div className='container'>
        <div className={styles.promo__wrapper}>
          <div className={styles.promo__info}>
            <div className={styles.promo__info_inner}>
              <h1 className={styles.promo__title}>
                {t("The World's First")} <span>{t("Blockchain-based")}</span> {t("System for Secured Laboratory Analysis")}
              </h1>
              <h4 className={styles.promo__descr}>
                {t("We as an independent German startup enable producers, manufacturers, and vendors to store certificates of analysis (results of laboratory analyses) in the Blockchain.")}
              </h4>
            </div>
          </div>
          <img className={styles.promo__img} src={promo} alt="promo" />
        </div>
      </div>
    </section>
  ) 
}

export default Header;