import React from 'react'
import styles from './Loader.module.scss'
import logo from '../../img/logoMob.svg';

const Loader = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.loader__wrapper}>
        <div className={styles.loader__loader}>
          <svg className={styles.loader__circular} viewBox="25 25 50 50">
            <circle className={styles.loader__path} cx="50" cy="50" r="20" fill="none" stroke='#0091D2' strokeWidth="2" strokeMiterlimit="10"/>
          </svg>
          <img src={logo} alt="logo" />
        </div>
      </div>
    </div>
  )
}

export default Loader;