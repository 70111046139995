import React, { useState, useEffect, useContext } from "react";
import styles from "./Profile.module.scss";
import EditIcon from "../../../../img/imageComponents/EditIcon";
import { InvoiceTable } from "../../../../Components/InvoiceTable/InvoiceTable";
import ChangePassModal from "../.././components/ChangePassModal/ChangePassModal";
import axios from "axios";
import { Context } from "../../../..";
import Save from "../../../../img/imageComponents/Save";
import cn from "classnames";
import { useTranslation } from 'react-i18next';
import updateProfileField from "../../../../helpers/updateProfileField";
import { API_URL } from "../../../../http";
import PlusIcon from '../../../../img/imageComponents/PlusIcon';
import updateImage from '../../../../helpers/updateImage';

const profileData = {
  name: "John Gray",
  email: "johnGray@dory.com",
  phone: "+1 000 000 0000",
  password: "asdasdasd",
  company: "Donut factory co.",
  website: "www.website.com",
  address: "14 Kessler Street, Wewoka,ok, 34884 United States",
};

const Profile = ({userName, setUserName, isEditProfilePhoto, setIsEditProfilePhoto}) => {
  const { t } = useTranslation(); 
  // const [name, setName] = useState(profileData?.name || null);
  const [isEditName, setIsEditName] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);
  const [isEditPhone, setIsEditPhone] = useState(false);
  const [password, setPassword] = useState(profileData?.password || null);
  const [company, setCompany] = useState("");
  const [errorCompany, setErrorCompany] = useState(false);
  const [isEditCompany, setIsEditCompany] = useState(false);
  const [website, setWebsite] = useState("");
  const [errorWebsite, setErrorWebsite] = useState(false);
  const [isEditWebsite, setIsEditWebsite] = useState(false);
  const [address, setAddress] = useState("");
  const [errorAdress, setErrorAddress] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [tab, setTab] = useState(1);
  const [isOpenChangePass, setIsOpenChangePass] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");
  // const [isEditProfilePhoto, setIsEditProfilePhoto] = useState(false);
  const [profilPhotoName, setProfilPhotoName] = useState(null);
  const [getedProfilePhoto, setGetedProfilePhoto] = useState("");
  const [getedCompanyPhoto, setGetedCompanyPhoto] = useState("");

  const [companyPhoto, setCompanyPhoto] = useState("");
  const [isEditCompanyPhoto, setIsEditCompanyPhoto] = useState(false);
  const [companyPhotoName, setCompanyPhotoName] = useState(null);
  const {store} = useContext(Context);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_URL}/user/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        "Accept": "application/json"
      }
    })
    .then((response) => {
      const { 
      companyAddress, companyWebsite, 
      email, phoneNumber, username, companyName, companyLogo, userLogo } = response?.data;

      setAddress(companyAddress || "—");
      setWebsite(companyWebsite || "—");
      setEmail(email || "—");
      setPhone(phoneNumber || "—");
      setUserName(username || "—");
      setCompany(companyName || "—");
      setGetedProfilePhoto(userLogo || "");
      setGetedCompanyPhoto(companyLogo || "");
    })
    .catch(e => {
      if(e.response.status === 401){
        store.logout();
      }
    })
  }, [])

  const updateField = (url, key, value, setter, setError) => {
    if(!value.length){
      setError(true);
    }else{
      setError(false);
    }
    updateProfileField(url, key, value)
    .then(response => {
      setter(prev => !prev);
    })
    .catch(error => {
    })
  }

  const handleImageName = (e, setterPhoto, setterName) => {
    setterPhoto(e.target?.files[0]);
    setterName(e.target?.files[0].name);
  }

  const handleProfileImage = (url, name, photo, setEdit) => {
    updateImage(url, name, photo)
    .then(function (response) {
      setEdit(false);
    })
    .catch(function (error) {
      setEdit(false);
    });
  }

  return (
    <div className={styles.profile__full}>
      <section className={styles.profile}>
        <h2 className={styles.profile__title}>
        {t("Profile")}
          </h2>
        <div className={styles.profile__info}>
          <div className={styles.profile__info_wrapper}>
            <div className={styles.profile__info_photo}>
              <div>
                <label 
                  className={cn(styles.profile__info_photo_label, isEditProfilePhoto ? styles.profile__info_photo_label_active : '')}
                >
                  {
                    profilePhoto ? 
                    <img 
                      className={cn(styles.profile__photoUser, styles.profile__photoUser_upper)}
                      src={URL.createObjectURL(profilePhoto)}  
                      alt="" 
                    /> 
                    :
                    null
                  }
                  {
                    getedProfilePhoto ? 
                    <img 
                      className={styles.profile__photoUser}
                      src={`${API_URL}/uploads/company-logos/${getedProfilePhoto}`}
                      alt="getedProfilePhoto" 
                    />
                    :
                    null
                  }
                  <input
                    type="file" 
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    onChange={(e) => handleImageName(e, setProfilePhoto, setProfilPhotoName)}
                  />
                  <div>
                    <PlusIcon />
                  </div>
                </label>
                <div className={styles.profile__info_photo_accept}>
                {
                  profilPhotoName !== null ?
                  <span className={styles.profile__info_photo_accept_name}>
                    {profilPhotoName}
                  </span>
                  :
                  <span>
                    {t("Add image .jpg or .png, 3mb max")}
                  </span>
                }
                </div>
              </div>
              {
                isEditProfilePhoto ? 
                <button
                  className={styles.editButton}
                  onClick={() => handleProfileImage('/user/upload-image/user-avatar', 'userAvatar', profilePhoto, setIsEditProfilePhoto)}
                >
                  <Save />
                </button>
                :
                <button 
                  className={styles.editButton}
                  onClick={() => setIsEditProfilePhoto(prev => !prev)}
                >
                  <EditIcon />
                </button>
              }
            </div>
            <div className={styles.profile__info_right}>
              <div className={styles.profile__info_right_wrapper}>
              <div className={styles.profile__info_block}>
                <div className={styles.profile__info_block_title}>{t("Name")}</div>
                <div className={styles.profile__info_block_value}>
                  <div className={styles.profile__info_block_value_text}>
                    <input 
                      type="text" 
                      className={cn(styles.profile__input, isEditName ? styles.profile__input_editable : '', errorName ? styles.border__red : '')}
                      value={userName} 
                      onChange={(e) => setUserName(e.target.value)}
                      readOnly={!isEditName}
                    />
                  </div>
                  {
                    isEditName ? 
                    <button
                      className={styles.editButton}
                      onClick={() => updateField('/user/updateName', "name", userName, setIsEditName, setErrorName)}
                    >
                      <Save />
                    </button>
                    :
                    <button 
                      className={styles.editButton}
                      onClick={() => setIsEditName(prev => !prev)}
                    >
                      <EditIcon />
                    </button>
                  }
                </div>
              </div>
                <div className={styles.profile__info_block}>
                  <div className={styles.profile__info_block_title}>
                  {t("Phone number")}
                  </div>
                  <div className={styles.profile__info_block_value}>
                    <div className={styles.profile__info_block_value_text}>
                      <input
                        type="text" 
                        className={cn(styles.profile__input, isEditPhone ? styles.profile__input_editable : '', errorPhone ? styles.border__red : '')}
                        value={phone} 
                        onChange={(e) => setPhone(e.target.value)} 
                        readOnly={!isEditPhone}
                      />
                    </div>
                    {
                      isEditPhone ? 
                        <button
                          className={styles.editButton}
                          onClick={() => updateField('/user/update-phone-number', "phoneNumber", phone, setIsEditPhone, setErrorPhone)}
                        >
                          <Save />
                        </button>
                        :
                        <button 
                          className={styles.editButton}
                          onClick={() => setIsEditPhone(prev => !prev)}
                        >
                          <EditIcon />
                        </button>
                    }
                  </div>
                </div>
              </div>
              <div className={styles.profile__info_right_wrapper}>
                <div className={styles.profile__info_block}>
                  <div className={styles.profile__info_block_title}>{t("Email")}</div>
                  <div className={styles.profile__info_block_value}>
                    <div className={styles.profile__info_block_value_text}>
                      <input 
                        type="text" 
                        className={cn(styles.profile__input, isEditEmail ? styles.profile__input_editable : '', errorEmail ? styles.border__red : '')}
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly={!isEditEmail}
                      />
                    </div>
                    {
                      isEditEmail ? 
                      <button
                        className={styles.editButton}
                        onClick={() => updateField('/user/update-email', "email", email, setIsEditEmail, setErrorEmail)}
                      >
                        <Save />
                      </button>
                      :
                      <button 
                        className={styles.editButton}
                        onClick={() => setIsEditEmail(prev => !prev)}
                      >
                        <EditIcon />
                      </button>
                    }
                  </div>
                </div>
                <div className={styles.profile__info_block}>
                <div className={styles.profile__info_block_title}>{t("Password")}</div>
                <div className={styles.profile__info_block_value}>
                  <div className={styles.profile__info_block_value_text}>
                    <input
                      type="password" 
                      className={styles.profile__input}
                      value={password} 
                      readOnly
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button
                    className={styles.editButton}
                    onClick={() => setIsOpenChangePass((prev) => !prev)}
                  >
                    <EditIcon />
                  </button>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className={styles.profile__info_bottom}>
            <div className={`${styles.profile__info_block} ${styles.profile__info_block_company}`}>
              <div className={styles.profile__info_block_title}>
                {t("Company Logo")}
              </div>
              <div
                className={`${styles.profile__info_block_value} ${styles.profile__info_block_value_logo}`}
              >
                <div>
                  <label 
                    className={cn(styles.profile__info_block_file, isEditCompanyPhoto ? styles.profile__info_photo_label_active : '')}
                  >
                    {
                      companyPhoto ? 
                      <img 
                        className={cn(styles.profile__photoUser, styles.profile__photoUser_upper)}
                        src={URL.createObjectURL(companyPhoto)}  
                        alt="" 
                      /> 
                      :
                      null
                    }
                    {
                      getedCompanyPhoto ? 
                      <img 
                        className={styles.profile__photoUser}
                        src={`${API_URL}/uploads/company-logos/${getedProfilePhoto}`}
                        alt="getedProfilePhoto" 
                      />
                      :
                      null
                    }
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      onChange={(e) => handleImageName(e, setCompanyPhoto, setCompanyPhotoName)}
                    />
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5V19"
                        stroke="#AEAEAE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 12H19"
                        stroke="#AEAEAE"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </label>
                  <div className={styles.profile__info_block_file_accept}>
                  {/* {t("Add image .jpg or .png, 3mb max")} */}
                    {
                      companyPhotoName !== null ?
                      <span className={cn(styles.profile__info_photo_accept_name, styles.profile__acceptCompany)}>
                        {companyPhotoName}
                      </span>
                      :
                      <span>
                        {t("Add image .jpg or .png, 3mb max")}
                      </span>
                    }
                  </div>
                </div>
                {
                  isEditCompanyPhoto ? 
                  <button
                    className={styles.editButton}
                    onClick={() => handleProfileImage('/user/upload-image/company-logo', 'companyLogo', companyPhoto, setIsEditCompanyPhoto)}
                  >
                    <Save />
                  </button>
                  :
                  <button 
                    className={styles.editButton}
                    onClick={() => setIsEditCompanyPhoto(prev => !prev)}
                  >
                    <EditIcon />
                  </button>
                }
              </div>
            </div>
            <div className={styles.profile__info_bottom_right}>
              <div className={styles.profile__info_bottom_right_wrapper}>
                <div className={styles.profile__info_block}>
                  <div className={styles.profile__info_block_title}>
                  {t("Company Name")}
                  </div>
                  <div className={styles.profile__info_block_value}>
                    <div className={styles.profile__info_block_value_text}>
                      <input 
                        type="text" 
                        className={cn(styles.profile__input, isEditCompany ? styles.profile__input_editable : '', errorCompany ? styles.border__red : '')}
                        value={company} 
                        onChange={e => setCompany(e.target.value)}
                        readOnly={!isEditCompany}
                      />
                    </div>
                    {
                      isEditCompany ? 
                      <button
                        className={styles.editButton}
                        onClick={() => updateField('/user/update-company-name', "companyName", company, setIsEditCompany, setErrorCompany)}
                      >
                        <Save />
                      </button>
                      :
                      <button 
                        className={styles.editButton}
                        onClick={() => setIsEditCompany(prev => !prev)}
                      >
                        <EditIcon />
                      </button>
                    }
                  </div>
                </div>
                <div className={styles.profile__info_block}>
                  <div className={styles.profile__info_block_title}>
                  {t("Company website")}
                  </div>
                  <div className={styles.profile__info_block_value}>
                    <div className={styles.profile__info_block_value_text}>
                      <input 
                        type="text" 
                        className={cn(styles.profile__input, isEditWebsite ? styles.profile__input_editable : '', errorWebsite ? styles.border__red : '')}
                        value={website} 
                        onChange={e => setWebsite(e.target.value)} 
                        readOnly={!isEditWebsite}
                      />
                    </div>
                    {
                      isEditWebsite ? 
                      <button
                        className={styles.editButton}
                        onClick={() => updateField('/user/update-company-website', "companyWebsite", website, setIsEditWebsite, setErrorWebsite)}
                      >
                        <Save />
                      </button>
                      :
                      <button 
                        className={styles.editButton}
                        onClick={() => setIsEditWebsite(prev => !prev)}
                      >
                        <EditIcon />
                      </button>
                    }
                  </div>
                </div>
              </div>
              <div className={styles.profile__info_block}>
                <div className={styles.profile__info_block_title}>
                  {t("Company Address")}
                </div>
                <div className={styles.profile__info_block_value}>
                  <div className={styles.profile__info_block_value_text}>
                    <textarea 
                      className={cn(styles.profile__textarrea, isEditAddress ? styles.profile__textarrea_editable : "", errorAdress ? styles.border__red : '')}
                      value={address} 
                      onChange={e => setAddress(e.target.value)} 
                      readOnly={!isEditAddress}
                    />
                  </div>
                  {
                    isEditAddress ? 
                    <button
                      className={styles.editButton}
                      onClick={() => updateField('/user/update-company-address', "companyAddress", address, setIsEditAddress, setErrorAddress)}
                    >
                      <Save />
                    </button>
                    :
                    <button 
                      className={styles.editButton}
                      onClick={() => setIsEditAddress(prev => !prev)}
                    >
                      <EditIcon />
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.invoices}>
        <h2 className={styles.profile__title}>{t("Invoices")}</h2>
        <div className={styles.invoices__container}>
          <div className={styles.invoices__tabs}>
            <div
              className={`${styles.invoices__tabs_tab} ${
                tab === 1 ? styles.invoices__tabs_tab_active : ""
              }`}
              onClick={() => setTab(1)}
            >
              {t("All Invoices")}
            </div>
          </div>
          <InvoiceTable />
        </div>
      </section>
      {isOpenChangePass && (
        <ChangePassModal closeAction={setIsOpenChangePass} />
      )}
    </div>
  );
};

export default Profile;
