import React, { useEffect, useState, useContext } from "react";
import styles from "./InvoiceTable.module.scss";
import DownloadIcon from "../../img/imageComponents/DownloadIcon";
import Status from "../Status/Status";
import axios from "axios";
import { API_URL } from "../../http";
import { useTranslation } from 'react-i18next';
import { Context } from '../../index';
import getRequest from "../../helpers/getRequest";
import Loader from '../Loader/Loader';


export const InvoiceTable = () => {
  const { t } = useTranslation(); 
  // const [fakeData, setFakeDate] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [loader, setLoader] = useState(false);
  const {store} = useContext(Context);

  function downloadPDF(pdf, certId) {
    const fileURL = URL.createObjectURL(pdf);
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = `cert${certId}.pdf`;
    alink.click();
}

  const getPdf = (certId) => {
    setLoader(true);
    axios({
      method: "get",
      url: `${API_URL}/invoice/${certId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
      responseType: "blob",
    })
    .then(res => {
      setLoader(false);
      downloadPDF(res.data, certId);
    })
    .catch(err => {
      console.log(err);
      setLoader(false);
    })
  }

  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_URL}/invoice`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        "Accept": "application/json"
      }
    })
    .then((response) => {
      setInvoice(response.data)
    })
    .catch(error => {
      if(error.response.status === 401){
        store.logout();
      }
    })
  }, [])

  return (
    <>
    {
      loader ?
      <Loader/>
      :
      null
    }
    <div className={styles.invoices__table}>
      {
        invoice && invoice.length ? 
        <>
          <div className={styles.invoices__header}>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__amount}`}
        >
          {t("Amount")}
        </div>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__status}`}
        >
          {t("Status")}
        </div>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__number}`}
        >
          {t("Invoice Number")}
        </div>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__date}`}
        >
          {t("Date")}
        </div>
        <div
          className={`${styles.invoices__header_title} ${styles.invoices__action}`}
        >
          {t("Action")}
        </div>
      </div>
      <>
        {invoice?.map((item) => {
          const { totalPrice, status, id, date, invoiceLink } = item;
          return (
            <div className={styles.invoices__row} key={id}>
              <div
                className={`${styles.invoices__amount} ${styles.invoices__row_data}`}
              >
                {totalPrice}
              </div>
              <div
                className={`${styles.invoices__status} ${styles.invoices__row_data}`}
              >
                <Status text={status} />
              </div>
              <div
                className={`${styles.invoices__number} ${styles.invoices__row_data}`}
              >
                {id}
              </div>
              <div
                className={`${styles.invoices__date} ${styles.invoices__row_data}`}
              >
                {date}
              </div>
              <div
                className={`${styles.invoices__action} ${styles.invoices__row_data}`}
              >
                <span onClick={() => getPdf(id)} style={{cursor: "pointer"}}>
                  <DownloadIcon/>
                </span>
              </div>
            </div>
          );
        })}
      </> 
      </>
        :
        <div className={styles.invoices__empty}>
          {t("Invoice data is empty")} ❌
        </div>
      }
    </div>
    </>
  );
};
