import axios from "axios"
import { API_URL } from "../http"

const updateProfileField = (url, key, value) => {
  return axios({
    method: 'patch',
    url: `${API_URL}${url}`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
      "Accept": "application/json"
    },
    data: {
      [`${key}`]: value
    }
  })
}


export default updateProfileField