import React from 'react'
import ReactDOM from 'react-dom'
import styles from './Modal.module.scss'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Modal = ({ email }) => {
  const { t } = useTranslation(); 
  return (
    ReactDOM.createPortal(
      <div className={styles.modal}>
        <div className={styles.modal__inner}>
          <div className={styles.modal__title}>
            {t("Verification link was sent to your email address")}
          </div>
          <div className={styles.modal__descr}>
            {t("Please check your mailbox and follow the link to")}
            <div>{email}</div>
          </div>
          <Link
            className={styles.modal__back} 
            to={'/'}
          >
            <div>
              {t("To main page")}
            </div>
          </Link>
        </div>
      </div>,
      document.getElementById('portal')
    )
  )
}
