import React, { memo } from "react";

const IconLink = memo(({ color = "#0091D2" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6858 13C11.1152 13.5741 11.6631 14.0491 12.2923 14.3929C12.9215 14.7367 13.6173 14.9411 14.3324 14.9923C15.0476 15.0435 15.7654 14.9403 16.4371 14.6897C17.1089 14.4392 17.7189 14.047 18.2258 13.54L21.2258 10.54C22.1366 9.59695 22.6406 8.33394 22.6292 7.02296C22.6178 5.71198 22.0919 4.45791 21.1649 3.53087C20.2378 2.60383 18.9838 2.07799 17.6728 2.0666C16.3618 2.0552 15.0988 2.55918 14.1558 3.46997L12.4358 5.17997"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6858 11C14.2564 10.4259 13.7084 9.9508 13.0793 9.60704C12.4501 9.26328 11.7543 9.05886 11.0392 9.00765C10.324 8.95643 9.60622 9.05961 8.93446 9.3102C8.2627 9.56079 7.65269 9.95291 7.14581 10.46L4.14581 13.46C3.23502 14.403 2.73105 15.666 2.74244 16.977C2.75383 18.288 3.27967 19.542 4.20671 20.4691C5.13376 21.3961 6.38782 21.922 7.6988 21.9334C9.00979 21.9447 10.2728 21.4408 11.2158 20.53L12.9258 18.82"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default IconLink;
