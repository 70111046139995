import React from 'react'
import { Outlet } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';

const Layout = ({user, setUser, lang, setLang, userName, isEditProfilePhoto}) => {
  return (
    <>
      <Header
        user={user}
        setUser={setUser}
        lang={lang}
        setLang={setLang}
        userName={userName}
        isEditProfilePhoto={isEditProfilePhoto}
      />
        <Outlet context={{user}}/>
      <Footer />
    </>
  )
}

export default Layout;