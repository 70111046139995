import { useEffect } from 'react';
import styles from './Warning.module.scss';
import '../../../../common.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import warning from '../../../../img/warning.png'

const Warning = () => {
  const { t } = useTranslation(); 

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className={styles.warning}>
      <div className='container'>
        <div className={styles.warning__wrapper}>
          <div className={styles.warning__image}>
            <img src={warning} alt="warning" data-aos="fade-up-right" data-aos-duration="1000"/>
          </div>
          <div className={styles.warning__descr}>
            <div className={styles.warning__descr_title}>
              {t("Certificates of analysis are increasingly being manipulated or falsified worldwide.")}
            </div>
            <br /><br />
            {t("In the case of economically unfavorable results for the manufacturers and producers, these digital certificates of analysis are manipulated and the stakeholders, who use the analytical data of the analyses as quality characteristics, are deceived. ")}<br /><br />
            {t("Especially intermediaries and end consumers cannot verify the authenticity of certificates of analysis and are forced to trust the information provided. ")}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Warning;