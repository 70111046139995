import axios from 'axios';

// export const API_URL = `http://cbl.ex2.team:8084`;
//export const API_URL = `https://https://lab-authenticity.org`;
export const API_URL = `https://back.lab-authenticity.org`;
// export const API_URL = `http://localhost:3000`;

const $api = axios.create({
  baseURL: API_URL
})

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  return config;
})

$api.interceptors.response.use((config) => {
  return config;
}, async (error) => {
  const originalRequest = error.config;
  if(error.response.status == 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try{
      // const response = await axios.get(`${API_URL}/auth/refresh`, {withCredentials: true})
      // localStorage.setItem('token', response.data.accessToken)
      const response = await axios({
        method: 'get',
        url: `${API_URL}/auth/refresh`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('tokenRefresh')}`,
          'Content-Type': 'application/json'
        }
      })
      localStorage.setItem('token', response?.data?.accessToken);
      return $api.request(originalRequest)
    } catch (e) {
      console.log('No auth')
      console.log('Navigate to login')
      // delete Token from localstorage
      localStorage.removeItem('token');
      localStorage.removeItem('tokenRefresh');
    }
  }
  throw error;
})

export default $api; 