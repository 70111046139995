import React, { useState } from 'react'
import styles from './Input.module.scss'
import ShowIcon from '../../img/imageComponents/ShowIcon'
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const Input = (({ placeholder, label, type = "text", required = false, value="", onChange, error="" }) => {
  const { t } = useTranslation(); 
  const [showPass, setShowPass] = useState(false);

  return (
    <label className={styles.label}>
      <span className={styles.span}>
      {t(label)}
      </span>
      <input 
        type={type === ("text" || "email") ? type : (type === "password" && showPass) ? "text" : "password"}
        placeholder={t(placeholder)}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        className={cn(styles.input, (error && error.length) ? styles.input__error : '')}
        required={required}
      />
      {
        error.length ? 
        <div className={styles.error}>
          {t(error)}
        </div>
        :
        null
      }
      {
        type === "password" &&
        <button 
          className={styles.show}
          onClick={() => setShowPass(prev => !prev)}
          type="button"
        >
          <ShowIcon show={showPass ? true : false} />
        </button>
      }
    </label>
    
  )
})

export default Input;