import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import jwt_decode from "jwt-decode";
import * as dayjs from 'dayjs'
import axios from "axios";
import { API_URL } from "../http";
export default class Store {

  isAuth = false;

  userId;

  registred = false;

  errorRegistraion = [];

  showPageCheckPost = false;
  loginError = [];
  showMainPage = false;
  isLoadSignUp = false;
  isLoadLogin = false;


  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool){
    this.isAuth = bool;
  }

  setUserId(id){
    this.userId(id)
  } 

  async verifyUser(id){
    try{
      const response = await AuthService.verifyUser(id);
      this.userId = id;
      this.showPageCheckPost = false;
      this.showMainPage = false;
    } catch(e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
    }
  }

  // done
  async login(email, password){
    this.isLoadLogin = true;
    try{
      const response = await AuthService.login(email, password);
      localStorage.setItem('token', response.data.accessToken);
      localStorage.setItem('tokenRefresh', response.data.refreshToken);
      this.setAuth(true);
      this.isLoadLogin = false;
      this.showPageCheckPost = false;
      this.showMainPage = false;
    } catch(e) {
      this.isLoadLogin = false;
      console.log(e)
      this.showPageCheckPost = false;
      this.showMainPage = false;
      if(e.response.status === 400){
        this.loginError = []
        this.loginError = [...this.loginError, ...e.response.data.message]
      }
      if(e.response.status === 401){
        this.loginError = []
        this.loginError.push(e.response.data.message)
      }
    }
  }


  async registration(username, email, password){
    try{
      const response = await AuthService.registration(username, email, password);
      localStorage.setItem('token', response.data.accessToken);
      this.setAuth(true);
    } catch(e) {
      console.log(email, password)
    }
  }

  // done
  async signup(username, email, password, lang){
    this.isLoadSignUp = true;
    try{
      const response = await AuthService.signup(username, email, password, lang);
      this.registred = true;
      this.errorRegistraion = [];
      this.acceptNavigate = true;
      this.showPageCheckPost = true;
      this.showMainPage = false;
      this.isLoadSignUp = false;
    } catch(e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
      this.isLoadSignUp = false;
      if(e.response.data.error){
        this.errorRegistraion = [];
        this.errorRegistraion = [...this.errorRegistraion, e.response.data.message]
      }
      if(e.response.status == 422){
        this.errorRegistraion = [];
        this.errorRegistraion.push(e.response.data.message)
      }

    }
  }

  async logout(){    
    try{
      // const response = await AuthService.logout();
      // console.log(response)
      console.log('logout1')
      localStorage.removeItem('token');
      localStorage.removeItem('tokenRefresh');
      this.setAuth(false);
      this.showPageCheckPost = false;
      this.showMainPage = true;
    } catch(e) {
      console.log('logout1')
      this.showPageCheckPost = false;
      this.showMainPage = false;
    }
  }



  async checkAuth() {
    try {
      // if access ok and refresh not exp
      const token = jwt_decode(localStorage.getItem('tokenRefresh'));
      const isExpired = dayjs.unix(token.exp).diff(dayjs()) < 1;
      if(localStorage.getItem('token') != null || isExpired){
        this.setAuth(true);
        this.showPageCheckPost = false;
        this.showMainPage = false;
      }
    } catch (e) {
      this.showPageCheckPost = false;
      this.showMainPage = false;
    }
  }

}