import React from 'react'
import styles from './NewsPage.module.scss'
import { useTranslation } from 'react-i18next';
import "../../common.css";
import about from '../../img/about3.png'

export const NewsPage3 = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.news}>
      <div className="container">
        <div className={styles.news__title}>{t("News")}</div>
        <div className={styles.news__subtitle}>{t("Lab-Authenticity.org will be launched Soon")}</div>
        <div className={styles.news__descr}>
          {t("The last adjustments have been made to our platform Lab-Authenticity.org and everything is ready for the launch. All functionalities and payment options as well as the blockchain connection are working without any problems. Currently, we are in talks with numerous partners and will also announce the first partnerships shortly.")}
        </div>
        <img style={{marginTop: '30px'}} src={about} alt="" />
      </div>
    </section>
  )
}
