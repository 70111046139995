import React from "react";
import styles from "./Privacy.module.scss";
import "../../common.css";
import { useTranslation } from 'react-i18next';


const Privacy = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.imprint}>
      <div className="container">
        <div className={styles.imprint__title}>
          {t("Privacy Policy")}
        </div>
        <div className={styles.imprint__text}>
          {t("Privacy policy for Lab-Authenticity.org, provided by CeBiol Blockchain Solutions GmbH, Mittelweg 144, 20148 Hamburg, Germany:")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Data protection Overview")}
        </div>
        <div className={styles.imprint__text}>
          <div className={styles.imprint__text_title}>{t("General notes")}</div>
          {t("The following notices provide a simple overview of what happens to your personal data when you visit our website or use our platform. Personal data is any data by which you can be personally identified. For detailed information on data protection, please refer to our privacy policy.")}
          <div className={styles.imprint__text_title}>{t("Data collection on our website")}</div>
          <div className={styles.imprint__text_title}>{t("Who is responsible for data collection on this website?")}</div>
          {t("Data processing on this website is carried out by the website operator. You can find his contact details in the imprint of this website.")}
          <div className={styles.imprint__text_title}>{t("How do we collect your data?")}</div>
          {t("On the one hand, your data is collected by you providing it to us. This can be, for example, data that you enter in a contact form.")}
          <br />
          {t("Other data is collected automatically by our IT systems when you visit the website. This is mainly technical data (e.g. Internet browser, operating system or time of page view). This data is collected automatically as soon as you enter our website.")}
          <div className={styles.imprint__text_title}>
            {t("What do we use your data for?")}
          </div>
          {t("Some of the data is collected to ensure error-free provision of the website. Other data may be used to analyze your user behavior.")}
          <div className={styles.imprint__text_title}>
          {t("What rights do you have regarding your data?")}
          </div>
          {t("You have the right at any time to receive information free of charge about the origin, recipient and purpose of your stored personal data. You also have the right to request the correction, blocking or deletion of this data. For this purpose, as well as for further questions on the subject of data protection, you can contact us at any time at the address given in the imprint. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.")}
          <div className={styles.imprint__text_title}>
          {t("Analysis tools and tools from third-party providers")}
          </div>
          {t("When visiting our website, your surfing behavior may be statistically analyzed. This is done primarily with cookies and with so-called analysis programs. The analysis of your surfing behavior is usually anonymous; the surfing behavior cannot be traced back to you. You can object to this analysis or prevent it by not using certain tools. Detailed information on this can be found in our privacy policy.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Data collection on our Platform")}
        </div>
        <div className={styles.imprint__text}>
        {t("We collect and store information that you voluntarily provide to us when you use our platform, especially when you upload lab analyses or retrieve certificates. This information may include your name, email address, address, phone number, or other contact information.")}
          <br />
          {t("We also collect information about how you use our Platform, such as your IP address, browser type, language, operating system, referral URL, date and time of access, and pages viewed.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Data Processing")}
        </div>
        <div className={styles.imprint__text}>
        {t("CeBiol Blockchain Solutions GmbH processes personal data only to the extent necessary to fulfill the purpose of the Platform. The processing includes, in particular, the storage and publication of laboratory analyses in the Blockchain. The processing is carried out exclusively on the basis of the provisions of data protection law.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Data Transmission")}
        </div>
        <div className={styles.imprint__text}>
          {t("Personal data is only transferred to third parties if this is necessary to fulfill the purpose of the platform. Transfer to third parties only takes place on the basis of a legal permission or consent of the user.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Data Security")}
        </div>
        <div className={styles.imprint__text}>
        {t("CeBiol Blockchain Solutions GmbH uses technical and organizational measures to protect personal data from loss, destruction, manipulation and unauthorized access. The measures correspond to the current state of the art and are regularly reviewed and adapted.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Rights of the Data Subjects")}
        </div>
        <div className={styles.imprint__text}>
          {t("Data subjects have the right to obtain information about the personal data stored about them, to have it corrected or deleted, and to restrict or object to processing. For the exercise of these rights, the data subjects may contact the contact details mentioned in point 1.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Deletion of Data")}
        </div>
        <div className={styles.imprint__text}>
        {t("Personal data will be deleted as soon as it is no longer needed for the purposes for which it was collected or the data subject revokes consent. However, deletion will not take place if legal obligations to retain data conflict with this. In this case, the data will be blocked and blocked for further processing")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Data transfer to third parties")}
        </div>
        <div className={styles.imprint__text}>
        {t("We only transfer your personal data to third parties if this is necessary for the provision of our services or if there is a legal obligation to do so. We do not transfer data to third parties for other purposes. However, if we are required by law or court order to do so, we will disclose your data to bodies entitled to receive such information.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Modification of our privacy policy")}
        </div>
        <div className={styles.imprint__text}>
        {t("We reserve the right to amend this data protection declaration in order to adapt it to changed legal situations or in the event of changes to our services. The current data protection statement applies, which you can view on our website.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Your rights as a data subject")}
        </div>
        <div className={styles.imprint__text}>
        {t("As a data subject, you have the right to information about the data we have stored about you, as well as to correction, deletion or restriction of the processing of this data. You also have the right to data portability. In addition, you have the right to object to the processing of your personal data and to revoke your consent to the processing. Please contact our data protection officer for this purpose.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Right of complaint to the supervisory authority")}
          
        </div>
        <div className={styles.imprint__text}>
          {t("As a data subject, you have the right to complain to the competent supervisory authority if you believe that the processing of your personal data violates data protection regulations.")}
        </div>
        <br />
        {t("Status: April 2023")}
      </div>
    </section>
  );
};

export default Privacy;
