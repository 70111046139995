import React, { memo } from "react";

const QuestionIcon = memo(({ color = "#1C475C" }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 4.5C9.383 4.5 4 9.883 4 16.5C4 23.117 9.383 28.5 16 28.5C22.617 28.5 28 23.117 28 16.5C28 9.883 22.617 4.5 16 4.5ZM16 6.5C21.535 6.5 26 10.965 26 16.5C26 22.035 21.535 26.5 16 26.5C10.465 26.5 6 22.035 6 16.5C6 10.965 10.465 6.5 16 6.5ZM16 10.5C13.8 10.5 12 12.3 12 14.5H14C14 13.383 14.883 12.5 16 12.5C17.117 12.5 18 13.383 18 14.5C17.9997 14.8737 17.8818 15.2379 17.6631 15.5408C17.4443 15.8438 17.1357 16.0702 16.781 16.188L16.375 16.312C15.9753 16.4467 15.6278 16.7031 15.3812 17.0453C15.1346 17.3874 15.0013 17.7982 15 18.22V19.5H17V18.219L17.406 18.094C18.1595 17.8407 18.8148 17.358 19.28 16.7134C19.7452 16.0689 19.997 15.2949 20 14.5C20 12.3 18.2 10.5 16 10.5ZM15 20.5V22.5H17V20.5H15Z"
        fill={color}
      />
    </svg>
  );
});

export default QuestionIcon;
