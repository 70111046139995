import React from 'react'
import styles from './Payment.module.scss'

const Payment = ({img, text, id, setChosenPayment, value}) => {
  return (
    <label className={styles.payment} onClick={() => setChosenPayment(value)}>
      <input type="radio" name="payment"/>
      <span className={styles.payment__checkmark}></span>
      <div className={styles.payment__value}>
        <div className={styles.payment__value_block}>
          <img src={img} alt="icon" />
        </div>
        <div>{text}</div>
      </div>
    </label>
  )
}

export default Payment;