import React from 'react'
import Aside from '../../Components/Aside/Aside';
import Certificate from './components/Certificate/Certificate';
import styles from './AddNewCertificate.module.scss';

const AddNewCertificate = ({lang, type, setType, category, setCategory, group, setGroup, productName, setProductName, 
  dateAnalysis, setDateAnalysis, batch, setBatch, laboratory, setLaboratory, expDate, setExpDate, selectedCategory, setSelectedCategory, selectedGroup, setSelectedGroup}) => {
  return (
    <>
      <div className={styles.wrapper}>
        <Aside />
        <Certificate 
          lang={lang}
          type={type}
          setType={setType}
          category={category}
          setCategory={setCategory}
          group={group}
          setGroup={setGroup}
          productName={productName}
          setProductName={setProductName}
          dateAnalysis={dateAnalysis}
          setDateAnalysis={setDateAnalysis}
          batch={batch}
          setBatch={setBatch}
          laboratory={laboratory}
          setLaboratory={setLaboratory}
          expDate={expDate}
          setExpDate={setExpDate}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
        />
      </div>
    </>
  )
}

export default AddNewCertificate;