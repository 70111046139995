import React from "react";
import styles from "./Terms.module.scss";
import "../../common.css";
import { useTranslation } from 'react-i18next';


const Terms = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.imprint}>
      <div className="container">
        <div className={styles.imprint__title}>
          {t("Terms of use")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("General")}
        </div>
        <div className={styles.imprint__text}>
          {t("The use of the platform for storing laboratory analyses on the domain Lab-Authenticity.org implies the acceptance of these Terms of Use. These Terms of Use govern the relationship between the Customer and CeBiol Blockchain Solutions GmbH, which operates the platform for storing laboratory analyses on the domain Lab-Authenticity.org.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Subject matter of the contract")}
        </div>
        <div className={styles.imprint__text}>
          {t("The platform for transparent storage of laboratory analyses on the domain Lab-Authenticity.org enables customers to store their analysis certificates in the blockchain. For this purpose, the customer receives access to a subpage created by us, on which he can track the storage of his laboratory analysis in the blockchain. The storage of the analysis certificate in the Blockchain is subject to a fee for the customer.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Services")}
        </div>
        <div className={styles.imprint__text}>
          {t("CeBiol Blockchain Solutions GmbH provides the customer with the platform for transparent storage of laboratory analyses on the domain Lab-Authenticity.org. The customer can see on the subpage created by us how the storage of his laboratory analysis in the blockchain has been done. The customer receives a seal from us, which he can link in his store to confirm the authenticity of his lab analysis. The laboratories' duty to cooperate is of great importance here. The customer is not allowed to send the laboratory analyses to us himself in order to avoid falsifications and modifications. Instead, the respective laboratory must be asked to send us the analyses so that we receive an original document. If the laboratory does not cooperate or sends false or manipulated analyses, CeBiol Blockchain Solutions GmbH cannot assume any liability.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Usage Fees")}
        </div>
        <div className={styles.imprint__text}>
          {t("The use of the platform for transparent storage of laboratory analyses on the domain Lab-Authenticity.org is subject to a fee. The fee is $9,95 per analysis certificate and will be charged in advance. CeBiol Blockchain Solutions GmbH reserves the right to change the fee at any time.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Liability")}
        </div>
        <div className={styles.imprint__text}>
          {t("CeBiol Blockchain Solutions GmbH is not liable for any damage caused by the use of the platform for transparent storage of laboratory analyses on the domain Lab-Authenticity.org. The customer is obliged to ensure that the data uploaded by him are correct and legally harmless.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Data Protection")}
        </div>
        <div className={styles.imprint__text}>
        {t("CeBiol Blockchain Solutions GmbH undertakes to treat all personal data of the Customer confidentially and to use it exclusively within the framework of the applicable data protection laws. The Customer agrees to the collection, processing and use of his personal data in the context of the use of the platform for transparent storage of laboratory analyses on the domain Lab-Authenticity.org.")}
        </div>
        <div className={styles.imprint__subtitle}>
        {t("Obligation of the laboratories to Cooperate")}
        </div>
        <div className={styles.imprint__text}>
        {t("To ensure that we receive an original document and not a forgery or modified version, the client (company) must ask its laboratory to send us the analysis. The client is not allowed to send the laboratory analyses to us himself. The laboratories are obliged to cooperate with us to ensure the originality of the laboratory analyses.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Right of withdrawal and money-back guarantee")}
        </div>
        <div className={styles.imprint__text}>
          {t("There is no right of withdrawal or money-back guarantee, because the service is already irrevocably provided after payment. Therefore, the customer is obliged to inform himself before placing the order whether he wants to use the platform for transparent storage of laboratory analyses on the domain Lab-Authenticity.org and whether he can bear the costs. CeBiol Blockchain Solutions GmbH is not liable for the decisions of the customer.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Liability and warranty")}
        </div>
        <div className={styles.imprint__text}>
          {t("CeBiol Blockchain Solutions GmbH is only liable for intent and gross negligence. Liability for slight negligence is excluded. No warranty is given for the functionality of the platform and the correctness of the data stored on the platform. The customer is responsible for checking the data stored on the platform.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Data protection")}
        </div>
        <div className={styles.imprint__text}>
          {t("Personal data is processed in accordance with the applicable data protection regulations. The Customer agrees that his personal data may be used by CeBiol Blockchain Solutions GmbH for the provision of the service and for contacting him. The data will not be disclosed to third parties, unless there is a legal obligation or the Customer has expressly consented.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Changes of the terms of use")} 
        </div>
        <div className={styles.imprint__text}>
          {t("CeBiol Blockchain Solutions GmbH reserves the right to change the Terms of Use at any time. The Customer will be informed about changes to the Terms of Use by email. By continuing to use the Platform after an amendment to the Terms of Use, the Customer declares its consent to the amended terms.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Severability clause")}
        </div>
        <div className={styles.imprint__text}>
          {t("Should individual provisions of these Terms of Use be or become invalid, this shall not affect the validity of the remaining provisions. The ineffective provisions shall be replaced by a provision that comes closest to the economic purpose of the ineffective provisions.")}
        </div>
        <div className={styles.imprint__subtitle}>
          {t("Jurisdiction and applicable law ")}
        </div>
        <div className={styles.imprint__text}>
          {t("These Terms of Use and the entire legal relationship between the Customer and CeBiol Blockchain Solutions GmbH shall be governed by the laws of the Federal Republic of Germany. The place of jurisdiction for all disputes arising from this contract is Hamburg.")}
        </div>
      </div>
    </section>
  );
};

export default Terms;
