import React, {useEffect, useState, useContext} from 'react'
import styles from './Balance.module.scss'
import WarningTriangle from '../../../../img/imageComponents/WarningTriangle'
import Document from '../../../../img/imageComponents/Document'
import Package from '../Package/Package'
import Payment from '../Payment/Payment'
import paypalIcon from '../../../../img/paypal.svg';
import stripeIcon from '../../../../img/stripe.svg';
import cebioIcon from '../../../../img/cebio.svg';
import CheckoutIcon from '../../../../img/imageComponents/CheckoutIcon';
import { countPercent } from '../../../../helpers/countPercent';
import { InvoiceTable } from '../../../../Components/InvoiceTable/InvoiceTable';
import { Link } from 'react-router-dom'
import PaymentModal from '../PaymentModal/PaymentModal'
import Input from '../../../../Components/Input/Input';
import SuccessCoupon from '../SuccessCoupon/SuccessCoupon'
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { API_URL } from '../../../../http'
import { useNavigate } from 'react-router-dom'
import postCoupon from '../../../../helpers/postCoupon'
import { Context } from '../../../..'
import Checkbox from '../../../../Components/Checkbox/Checkbox'



const payment = [
  {img: paypalIcon, text: "Pay with Paypal", id: 1, value: "paypal"},
  {img: stripeIcon, text: "Pay with Stripe", id: 2, value: "stripe"},
  {img: cebioIcon, text: "Pay with CBSL Token", id: 3, value: "cbsl_token"}
]

const Balance = ({lang}) => {
  const { t } = useTranslation(); 
  const [chosenPackage, setChosenPackage] = useState(null);
  const [chosenPayment, setChosenPayment] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [isSuccessCertificate, setIsSuccessCertificate] = useState(false);
  const [isErrorCertificate, setIsErrorCertificate] = useState('');
  const [total, setTotal] = useState(0)
  const [paymentError, setPaymentError] = useState('');
  const [coupon, setCoupon] = useState('');
  const {store} = useContext(Context);
  const [invoiceId, setInvoiceId] = useState(null);
  const [price, setPrice] = useState(null);
  const [addressWalet, setAddressWalet] = useState(null);
  const [isCheckTerms, setIsCheckTerms] = useState(false);
  const [isCheckPrivacy, setIsCheckPrivacy] = useState(false);
  const [isAllowSubmit, setIsAllowSubmit] = useState(false);
  const [packages, setPackages] = useState(null)


  useEffect(() => {
    if(lang === 'de'){
      setPackages([
        {value: "1 Certificate", price: 10, id: 1},
        {value: "6 Certificates", price: 50, id: 2},
        {value: "10 Certificates", price: 85, id: 3}
      ])
    }else{
      setPackages([
        {value: "1 Certificate", price: 11, id: 1},
        {value: "6 Certificates", price: 55, id: 2},
        {value: "10 Certificates", price: 94, id: 3}
      ])
    }
  }, [lang])



  const checkout = () => {
    setPaymentError('');
    const data = { 
      paymentMethod: chosenPayment,
      optionId: chosenPackage.id
    }
    axios({
      method: 'post',
      url: `${API_URL}/invoice`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      data
    })
    .then(response => {
      if(response.data?.redirectLink){
        window.location.replace(response.data?.redirectLink);
      }
    })
    .catch(error => {
      if(error.response.status === 401){
        store.logout();
      }
      setPaymentError('Payment service is not responce now, try again later')
    })
  }

  const tokenPay = () => {
    const data = { 
      paymentMethod: chosenPayment,
      optionId: chosenPackage.id
    }
    axios({
      method: 'post',
      url: `${API_URL}/invoice`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      data
    })
    .then(response => {
      console.log(response);
      const { invoiceId, price, redirectLink } = response?.data;

      setInvoiceId(invoiceId)
      setPrice(price)
      setAddressWalet(redirectLink)

      fillModal(invoiceId, price, redirectLink);
    }) 
    .catch(error => console.log(error))
  }

  const checkCoupon = () => {
    setIsErrorCertificate('');
    postCoupon('invoice/apply', {couponCode: coupon})
    .then(response => {
      setIsSuccessCertificate(true);
    })
    .catch(error => {
      setIsSuccessCertificate(false);
      setIsErrorCertificate('Incorrect coupon');
    })
  }

  const fillModal = (invoiceId, price, addressWalet) => {
    setIsOpenPaymentModal(true);
  }

  useEffect(() => {
    if(
      (chosenPayment !== null) &&
      (chosenPackage !== null) &&
      (isCheckTerms) &&
      (isCheckPrivacy)
    ){
      setIsAllowSubmit(true)
    }else{
      setIsAllowSubmit(false)
    }
  }, [chosenPayment, chosenPackage, isCheckTerms, isCheckPrivacy])

  useEffect(() => {
    if(coupon === '') setIsErrorCertificate('');
  }, [coupon])

  useEffect(() => {
    setTotalPrice(chosenPackage?.price + countPercent(chosenPackage?.price))
  }, [chosenPackage])

  useEffect(() => {
    axios({
      method: "get",
      url: `${API_URL}/user/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      setTotal(response.data.certificatesBalance)
    })
    .catch((e) => {
      if(e.response.status === 401){
        store.logout();
      }
    });
  }, [])

  return (
    <section className={styles.balance}>
      <h2 className={styles.balance__title}>
      {t("Account balance")}
        </h2>
      <div className={styles.balance__current}>
        <div className={styles.balance__balance}>
          <WarningTriangle />
          <div>
          {t("Current Balance")}
        </div>
        </div>
        <div className={styles.balance__info}>
          <div className={styles.balance__stat}>
            <div className={styles.balance__stat_count}>{total}</div>
            <div className={styles.balance__stat_text}>
          {t("certificates available")}
              </div>
          </div>
          <Document
            color={'#E5EEF8'}
            width={"124px"}
            height={"124px"}
          />
        </div>
      </div>
      <div className={styles.balance__check}>
        <div className={styles.balance__check_left}>
          <div className={styles.balance__check_label}>
            {t("Choose the package:")}
          </div>
          {
            packages ?
            <div className={styles.balance__check_wrapper}>
              {
                packages.map(item => {
                  const {value, price, id} = item
                  return(
                    <Package  
                      value={value}
                      price={price}
                      key={id}
                      id={id}
                      lang={lang}
                      chosenPackage={chosenPackage}
                      setChosenPackage={setChosenPackage}
                    />
                  )
                })
              }
            </div>
            :
            null
          }
          <div className={styles.balance__coupon}>
            <Input 
              placeholder={t("type here")}
              label={t("I have a coupon")}
              value={coupon}
              onChange={setCoupon}
            />
            <button 
              className={styles.balance__coupon_button}
              onClick={() => checkCoupon()}
            >
              {t("Apply")}
            </button>
          </div>
          <div className={styles.coupon__error}>
            {t(isErrorCertificate)}
          </div>
        </div>
        <div className={`${styles.balance__check_right} ${!chosenPackage ? styles.balance__check_right_blur : ''}`}>
          <div className={styles.balance__check_label}>
          {t("Billing:")}
            </div>
          <div className={styles.balance__check_check}>
            <div className={styles.balance__check_value}>
              {
                chosenPackage && chosenPackage.value ? 
                <span>{t(chosenPackage.value)}</span>
                :
                <span>{t("Choose your package")}</span>
              }
            </div>
            <div className={styles.balance__check_price}>
              {
                chosenPackage && chosenPackage.price ? 
                `${lang === "de" ? "€ " : "USD "}` + chosenPackage.price.toFixed(2)
                :
                ""
              }
            </div>
          </div>
          <div className={styles.balance__check_summary}>
            <div className={styles.balance__check_title}>
              {t("Summary:")}
            </div>
            <div className={styles.balance__check_sum}>
              {
                lang && lang === "de" ?
                  (chosenPackage && totalPrice)? 
                  "€ " + totalPrice.toFixed(2)
                  :
                  "-"
                :
                (chosenPackage && totalPrice)? 
                "USD " + chosenPackage?.price.toFixed(2)
                :
                "-"
              }
            </div>
          </div>
          <div className={styles.balance__check_summary}>
            <div className={styles.balance__check_vat}> 
            {t("Include 19% VAT")}
            </div>
            <div className={styles.balance__check_vat_value}>
              {
                chosenPackage && chosenPackage.price ? 
                "€ " + countPercent(chosenPackage.price).toFixed(2)
                :
                "-"
              }
            </div>
          </div>
          <div className={styles.balance__payment}>
            {
              payment?.map(item => {
                const {img, text, id, value} = item
                return(
                  <Payment 
                    img={img}
                    text={t(text)}
                    value={value}
                    key={id}
                    id={id}
                    setChosenPayment={setChosenPayment}
                  />
                )
              })
            }
          </div>
          <div className={styles.balance__terms}>
            <Checkbox 
              text="I accept the terms of Service"
              value={isCheckTerms}
              onChange={() => setIsCheckTerms(prev => !prev)}
            />
            <Checkbox 
              text="I have taken note of the Privacy Policy"
              value={isCheckPrivacy}
              onChange={() => setIsCheckPrivacy(prev => !prev)}
            />
          </div>
          {
            chosenPayment !== "cbsl_token" ? 
            <div 
              className={`${styles.balance__checkout} ${!isAllowSubmit ? styles.balance__checkout_blur : ''}`}
              onClick={() => checkout()}
            >
              <CheckoutIcon />
              <div>
                {t("Proceed to Checkout")}
              </div>
            </div>
            :
            <div 
              className={`${styles.balance__checkout} ${!isAllowSubmit ? styles.balance__checkout_blur : ''}`}
              // onClick={() => setIsOpenPaymentModal(prev => !prev)}
              onClick={() => tokenPay()}
            >
              <CheckoutIcon />
              <div>{t("Proceed to Checkout")}</div>
            </div>
          }
          {
            paymentError && paymentError.length ? 
            <div className={styles.balance__payment_error}>
              {paymentError}
            </div>
            :
            null
          }
        </div>
      </div>
      <div className={styles.invoice}>
        <div className={styles.invoice__title}>
          {t("Invoices")}
        </div>
        <InvoiceTable />
      </div>
      {
        isOpenPaymentModal &&
        <PaymentModal 
          closeAction={setIsOpenPaymentModal}
          invoiceId={invoiceId}
          price={price}
          addressWalet={addressWalet}
        />
      }
      {
        isSuccessCertificate && 
        <SuccessCoupon 
          closeAction={setIsSuccessCertificate}
        />
      }
    </section>
  )
}

export default Balance;