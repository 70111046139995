import { useEffect } from 'react';
import styles from './Token.module.scss';
import '../../../../common.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import token from '../../../../img/token.svg';

const Token = () => {
  const { t } = useTranslation(); 
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className={styles.token}>
      <div className='container'>
        <div className={styles.token__content}>
          <div>
            <div className={styles.token__title}>
              {t("Link to the crypto market through the")} <span>{t("CBSL Token")}</span>
            </div>
            <div className={styles.token__descr}>
              {t("Lab-Authenticitiy.org is part of CeBioLabs, a German crypto startup. The CBSL Token is considered the link of all CeBioLabs projects and is therefore also accepted as a means of payment on Lab-Authenticitiy.org. All sales in dollars are converted into CBSL after deduction of taxes and locked according to the period defined in the whitepaper. Thus, the project has a direct link to the CBSL Token and the crypto market.")}
            </div>
          </div>
          <img className={styles.token__image} src={token} alt='token' data-aos="zoom-in" data-aos-duration="1000"/>
        </div>
      </div>
    </section>
  )
}

export default Token;