import React, { useEffect, useState, useContext, useRef } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SettingsContext from '../SettingsContext/SettingsContext';
import styles from './Timer.module.scss';

const Timer = () => {
  const settingsInfo = useContext(SettingsContext)
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [mode, setMode] = useState('work');

  const secondsLeftRef = useRef(secondsLeft);
  const modeRef = useRef(mode);

  const tick = () => {
    secondsLeftRef.current--;
    setSecondsLeft(secondsLeftRef.current);
  }

  const initTimer = () => {
    setSecondsLeft(settingsInfo.workMinutes * 60);
  }

  const switchMode = () => {
    const nextMode = modeRef === 'work' ? 'break': 'work'
    const nextSeconds = (nextMode === 'work' ? settingsInfo.workMinutes : settingsInfo.breakMinutes) * 60;
    setMode(nextMode);
    modeRef.current = nextMode;

    setSecondsLeft(nextSeconds);
    secondsLeftRef.current = nextSeconds;
  } 

  useEffect(() => {
    initTimer();

    const interval = setInterval(() => {
      if(secondsLeftRef.current === 0){
        return switchMode();
      }

      tick();
    }, 1000)

    return () => clearInterval(interval);
    // eslint-disable-next-line 
  }, [settingsInfo])

  const totalSeconds = mode === 'work' ? settingsInfo.workMinutes * 60 : settingsInfo.breakMinutes * 60;
  const percentage = Math.round(secondsLeft / totalSeconds * 100);


  const minutes = Math.floor(secondsLeft / 60);
  let seconds = secondsLeft % 60;

  if(seconds < 10) seconds = '0' + seconds;


  return (
    <div className={styles.timer}>
      <CircularProgressbar 
        value={percentage} 
        text={minutes + ":" + seconds} 
        strokeWidth={2}
        counterClockwise={true}
        className={styles.timer__inner}
        styles={buildStyles({
          textColor: "#0091D2",
          pathColor: "#0091D2",
          trailColor: "#FFFFFF",
      })}/>
    </div>
  )
}

export default Timer;