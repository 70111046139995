import React, { useState, useEffect } from "react";
import styles from "./ResetPass.module.scss";
import "../../common.css";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../Components/Input/Input";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import cn from "classnames";
import Loader from "../../Components/Loader/Loader";
import postRequest from "../../helpers/postRequest";
import { regEmail } from '../../helpers/regexp';
import { Modal } from "../../Components/Modal/Modal";


const ResetPass = ({lang}) => {
  const { t } = useTranslation(); 
  const [email, setEmail] = useState(null);
  const [errorEmail, setErrorEmail] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [serverError, setServerError] = useState("");
  const [loader, setLoader] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const navigate = useNavigate();

  const forgotPass = (email) => {
    setLoader(true);
    postRequest('auth/forgot-password', {email, lang})
    .then((response) => {
      setLoader(false);
      if(response.status === 201){
        setIsOpenModal(true)
      }
    }, (error) => {
      setLoader(false);
      if(error?.response?.data?.statusCode === 403){
        setServerError([error.response.data?.message])
      } else if(error?.response?.data?.statusCode === 400){
        setServerError(error.response.data?.message)
      }else{
        alert('Something went wrong')
      }
    });
  }

  const checkEmail = (value) => {
    setEmail(value.trim());
    setErrorEmail('')
    if(!value.length) setErrorEmail("Email cannot be empty")
    if(!regEmail.test(value)) setErrorEmail("Enter correct email")
  }

  useEffect(() => {
    AOS.init();
  }, [])


  useEffect(() => {
    setIsOpenModal(false)
  }, [navigate])

  useEffect(() => {
    if(email !== null && !errorEmail.length) {
      setAllowSubmit(true)
    }else{
      setAllowSubmit(false)
    }
  }, [email, errorEmail])

  return (
    <section className={styles.reset}>
      <div className="container">
        <div className={styles.reset__wrapper}>
          <div className={styles.reset__left}>
            <h2 className={styles.reset__title}>{t("Reset password")}</h2>
            <div className={styles.reset__content}>
              <div className={cn(styles.reset__block, styles.reset__block_active)}>
                <Input 
                  placeholder="email"
                  label="Your registration email"
                  required={true}
                  value={email}
                  onChange={checkEmail}
                  error={errorEmail}
                />
              </div>
            </div>
            <div className={styles.reset__btns}>
              <button 
                className={cn(styles.reset__next, !allowSubmit ? styles.reset__next_disabled : '')}
                onClick={() => forgotPass(email)}
              >
                {t("Send")}
              </button>
              <Link to={"/login"}>
                <button className={styles.reset__back}>
                  {t("Back")}
                </button>
              </Link>
            </div>
            {
              (serverError && serverError.length) ? 
              <div className={styles.reset__errors}>
                {
                  <>
                    {
                      <div>{t(serverError)}</div>
                    }
                  </>
                }
              </div>
              :
              null
            }
          </div>
          <div className={styles.reset__right} data-aos="fade-left" data-aos-duration="1000"></div>
        </div>
      </div>
      {
        loader ?
        <Loader />
        :
        null
      }
      {
        isOpenModal &&
        <Modal 
          email={email}
        />
      }
    </section>
  );
};

export default ResetPass;
