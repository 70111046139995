import React, { useState, useEffect } from "react";
import styles from "./Certificate.module.scss";
import Back from "../../../../Components/Buttons/Back/Back";
import certificate from "../../../../img/certificate.png";
import PayForm from "../PayForm/PayForm";
import Status from "../../../../Components/Status/Status";
import getRequest from "../../../../helpers/getRequest";
import Loader from "../../../../Components/Loader/Loader";
import { useParams } from "react-router-dom";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../../../../http";


const Certificate = ({selectedCategory, productName, batch, laboratory, dateAnalysis, expDate, type, selectedGroup, lang }) => {
  const [isLoad, setIsLoad] = useState(false);
  const [certificateInfo, setCertificateInfo] = useState(null);
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <section className={styles.certificate}>
      <Back path={"/certificates"} />
      <h2 className={styles.certificate__title}>
        {t("Type of analysis certificate")}
      </h2>
      <div className={styles.certificate__wrapper}>
        <div className={styles.certificate__certificate}>
          <img src={certificate} alt="certificate" />
        </div>
        {!certificateInfo ? (
          <div className={styles.certificate__info}>
            {selectedCategory?.categoryName ? (
              <div className={styles.certificate__block}>
                <div className={styles.certificate__label}>
                  {t("Product Category")}
                </div>
                <div className={styles.certificate__value}>
                  {selectedCategory.categoryName}
                </div>
              </div>
            ) : null}
            {productName ? (
              <div className={styles.certificate__block}>
                <div className={styles.certificate__label}>
                  {t("Product Name")}
                </div>
                <div className={styles.certificate__value}>
                  {productName}
                </div>
              </div>
            ) : null}
            {batch ? (
              <div className={styles.certificate__block}>
                <div className={styles.certificate__label}>
                  {t("Batch Number")}
                </div>
                <div className={styles.certificate__value}>
                  {batch}
                </div>
              </div>
            ) : null}
            {/* <div className={styles.certificate__block}>
              <div className={styles.certificate__label}>client (company)</div>
              <div className={styles.certificate__value}>
                {certificateInfo.client}
              </div>
            </div> */}
            {/* <div className={styles.certificate__block}>
              <div className={styles.certificate__label}>
                website of the company
              </div>
              <div className={styles.certificate__value}>
                {certificateInfo.website}
              </div>
            </div> */}
            {laboratory ? (
              <div className={styles.certificate__block}>
                <div className={styles.certificate__label}>
                  {t("Laboratory")}
                </div>
                <div className={styles.certificate__value}>
                  {laboratory}
                </div>
              </div>
            ) : null}
            <div className={styles.certificate__block}>
              <div className={styles.certificate__label}>
                {t("Status")}
              </div>
              <div className={styles.certificate__value}>
                <Status text='in_process' />
              </div>
            </div>
            {dateAnalysis ? (
              <div className={styles.certificate__block}>
                <div className={styles.certificate__label}>
                  {t("Date of Analysis")}
                </div>
                <div className={styles.certificate__value}>
                  {moment.unix(dateAnalysis/1000).format("DD.MM.YYYY")}
                </div>
              </div>
            ) : null}
            {expDate ? (
              <div className={styles.certificate__block}>
                <div className={styles.certificate__label}>
                  {t("Expiration Date")}
                </div>
                <div className={styles.certificate__value}>
                  {moment.unix(expDate/1000).format("DD.MM.YYYY")}
                </div>
              </div>
            ) : null}
            <PayForm
              type={type}
              selectedGroup={selectedGroup}
              batch={batch}
              laboratory={laboratory}
              dateAnalysis={dateAnalysis}
              expDate={expDate}
              productName={productName}
              lang={lang}
              setIsLoad={setIsLoad}
             />
          </div>
        ) : (
          <div className={styles.certificate__empty}>
            {t("Certificate empty")}
          </div>
        )}
      </div>
      {isLoad ? <Loader /> : null}
    </section>
  );
};

export default Certificate;
