import React, { useState, useEffect } from "react";
import styles from './VerifyReset.module.scss'
import '../../common.css'
import Input from '../Input/Input'
import { Link, useParams, useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import cn from "classnames";
import Loader from "../Loader/Loader";
import postRequest from "../../helpers/postRequest";
import checkPassword from "../../helpers/checkPassword";


const VerifyReset = () => {
  const { t } = useTranslation(); 
  const [password, setPassword] = useState(null);
  const [errorPassword, setErrorPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState(null);
  const [errorPasswordAgain, serErrorPasswordAgain] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [serverError, setServerError] = useState([]);
  const [loader, setLoader] = useState(false);

  const {id} = useParams();
  const navigate = useNavigate();

  const forgotPass = (password) => {
    setLoader(true);
    postRequest(`auth/reset/${id}`, {password})
    .then((response) => {
      setLoader(false);
      if(response.status === 201){
        navigate('/success-registration')
      }
    }, (error) => {
      setLoader(false);
      alert('Something went wrong')
    });
  }

  const checkPass = (value)  => {
    setPassword(value.trim());
    setErrorPassword('');
    checkPassword(value, setErrorPassword);
  } 

  const checkPasswordAgain = (value) => {
    setPasswordAgain(value.trim())
    serErrorPasswordAgain('')
    if(value !== password) serErrorPasswordAgain('Passwords must match')
  } 

  useEffect(() => {
    AOS.init();
  }, [])


  useEffect(() => {
    if(
      (password !== null && !errorPassword.length) &&
      (passwordAgain !== null && !errorPasswordAgain.length) && 
      (password === passwordAgain)
    ){
      setAllowSubmit(true)
    }else{
      setAllowSubmit(false)
    }
  }, [password, errorPassword, passwordAgain, errorPasswordAgain])

  return (
    <section className={styles.reset}>
      <div className="container">
        <div className={styles.reset__wrapper}>
          <div className={styles.reset__left}>
            <h2 className={styles.reset__title}>{t("Reset password")}</h2>
            <div className={styles.reset__content}>
              <div className={cn(styles.reset__block, styles.reset__block_active)}>
                <Input 
                  placeholder="•••••••••••••••"
                  label="New password"
                  type="password"
                  required={true}
                  value={password}
                  onChange={checkPass}
                  error={errorPassword}
                />
                <Input 
                  placeholder="•••••••••••••••"
                  label="Confirm password"
                  type="password"
                  value={passwordAgain}
                  onChange={checkPasswordAgain}
                  error={errorPasswordAgain}
                /> 
              </div>
            </div>
            <div className={styles.reset__btns}>
              <button 
                className={cn(styles.reset__next, !allowSubmit ? styles.reset__next_disabled : '')}
                onClick={() => forgotPass(password)}
              >
                Send
              </button>
              <Link to={"/login"}>
                <button className={styles.reset__back}>
                  {t("Back")}
                </button>
              </Link>
            </div>
            {
              (serverError && serverError.length) ? 
              <div className={styles.reset__errors}>
                {
                  <>
                    {
                      serverError?.map((item, index) => {
                        return(
                          <div key={index}>{item}</div>
                        )
                      }) 
                    }
                  </>
                }
              </div>
              :
              null
            }
          </div>
          <div className={styles.reset__right} data-aos="fade-left" data-aos-duration="1000"></div>
        </div>
      </div>
      {
        loader ?
        <Loader />
          :
        null
      }
    </section>
  )
}

export default VerifyReset;