import React, { useEffect, useContext } from 'react'
import styles from './Page404.module.scss'
import image from '../../img/404.svg'
import Back from '../../Components/Buttons/Back/Back';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Context } from "../../index";
import { useLocation, useNavigate } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';

const Page404 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const { isAuth } = useObserver(() => ({
    isAuth: store.isAuth,
  }));


  useEffect(() => {
    if(isAuth == false){
      if(
        (location.pathname === "/profile") ||
        (location.pathname.includes("/certificates")) ||
        (location.pathname === "/account-balance") || 
        (location.pathname === "/faq-profile") 
      ){
        navigate('/login')
      }
    }
  }, [location, localStorage])

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={styles.page} data-aos="zoom-in" data-aos-duration="1000">
      <img src={image} alt="404" />
      <div className={styles.page__title}>
        Error 404. Page not found
      </div>
      <Back path="/" img={false} classname="bgBlue"/>
    </section>
  )
}

export default Page404;