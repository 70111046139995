import { useEffect } from 'react';
import styles from './Advantages.module.scss';
import '../../../../common.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import Check from '../../../../img/imageComponents/Check';
import Button from '../../../../Components/Button/Button';
import { Link } from 'react-router-dom';


const advForProducers = [
  {text: 'Proof of the authenticity of the laboratory analyses of their own products', id: 0},
  {text: 'Storage of the issued certificates of analysis in the blockchain', id: 1},
  {text: 'Confirmation and timestamps to validate the original document', id: 2},
  {text: 'Subpage on Lab-Authenticity.org with product details and linking', id: 3},
  {text: 'Higher trustworthiness through the Lab-Authenticity.org authenticity seal', id: 4}
]

const advForCustomers = [
  {text: 'Recognition of genuine certificates of analysis', id: 0},
  {text: 'Independent technical proof of authenticity', id: 1},
  {text: 'Protection against harmful products', id: 2},
  {text: 'Identification of trustworthy suppliers through quality seals', id: 3}
]

const Advantages = () => {
  const { t } = useTranslation(); 

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className={styles.advantages}>
      <div className='container'>
        <div className={styles.advantages__wrapper}>
          <div className={styles.advantages__block} data-aos="fade-right" data-aos-duration="1000">
            <div>
              <div className={styles.advantages__block_title}>
                {t("Advantages for producers")}
              </div>
              {
                advForProducers.map(item => {
                  const {text, id} = item;
                  return(
                    <div className={styles.advantages__row} key={id}>  
                      <Check />
                      <div className={styles.advantages__text}>
                        {t(text)}
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <Link to="/register">
              <Button 
                width='131px'
                height='43px'
                text='Sign Up'
                color='whiteBlue'
              />
            </Link>
          </div>
          <div className={styles.advantages__block} data-aos="fade-left" data-aos-duration="1000">
            <div>
              <div className={styles.advantages__block_title}>
                {t("Advantages for end customers")}
              </div>
              {
                advForCustomers.map(item => {
                  const {text, id} = item;
                  return(
                    <div className={styles.advantages__row} key={id}>  
                      <Check color='#0091D2' />
                      <div className={styles.advantages__text}>
                        {t(text)}
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <Link to={'/register'}>
              <Button 
                width='131px'
                height='43px'
                text='Sign Up'
                color='blueWhite'
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Advantages;