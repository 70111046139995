import React, { useEffect } from 'react'
import styles from './CheckPost.module.scss';
import '../../common.css'
import thank from '../../img/thank.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const CheckPost = () => {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className={styles.check}>
      <div className="container">
        <div className={styles.check__wrapper}>
          <div className={styles.check__left}>
            <div className={styles.check__title}>
              Check email to verify
            </div>
          </div>
          <div className={styles.check__right}>
            <img data-aos="fade-left" data-aos-duration="1000" src={thank} alt="thank" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CheckPost;