import React, { useState } from "react";
import styles from "./Select.module.scss";
import Dropdown from "react-select";
import { useTranslation } from "react-i18next";

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "#E5EEF8",
    color: '#0091D2',
    height: "53px",
    borderRadius: "10px",
    border: "1px solid #0091D2",
    boxShadow: state.isFocused ? null : null,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};

const Select = ({ label, options, value, onChange, isGroup = false }) => {
  const { t } = useTranslation();
  return (
    <label className={styles.select}>
      <span className={styles.select__label}>{label}</span>
      <Dropdown
        styles={customStyles}
        placeholder={<div className={styles.placeholder}>{t("Search")}</div>}
        defaultValue={''}
        value={value}
        onChange={onChange}
        className={styles.dropdown}
        options={options}
        getOptionLabel ={(option)=> {
          if(!isGroup) return (t(option.categoryName))
          if(isGroup) return (t(option.groupName))
        }}
        getOptionValue ={(option)=>option.id}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </label>
  );
};

export default Select;
