import styles from './Benefits.module.scss';
import '../../../../common.css';
import "animate.css";
import { useTranslation } from 'react-i18next';
import BenefitsBlock from './Components/BenefitsBlock';

import benefits1 from '../../../../img/benefits1.svg';
import benefits2 from '../../../../img/benefits2.svg';
import benefits3 from '../../../../img/benefits3.svg';

const benefits = [
  {icon: benefits1, description: 'Producer receives the digital certificate. In case of unfavorable results, he could manipulate the digital document and deceive his customers.', id: 1},
  {icon: benefits2, description: 'Since customers trust the certificates of analysis, they are exposed to risk in case of tampered certificates.', id: 2},
  {icon: benefits3, description: 'In order to confirm the authenticity of his certificates of analysis, producer uses our offer', id: 3}
]

const Benefits = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.benefits}>
      <div className='container'>
        <h2 className={styles.benefits__title}> {t("Benefits")} <span>{t("to work with Lab-Authenticity")}</span></h2>
        <div className={styles.benefits__wrapper}>
          {
            benefits.map(item => {
              const {icon, description, id} = item;
              return(
                <BenefitsBlock 
                  key={id}
                  icon={icon}
                  description={description}
                />
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default Benefits;