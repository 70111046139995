import React, { useEffect } from "react";
import styles from "./ThankRegister.module.scss";
import "../../common.css";
import thank from '../../img/thank.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

const ThankRegister = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={styles.thank}>
      <div className="container">
        <div className={styles.thank__wrapper}>
          <div className={styles.thank__left}>
            <h2 className={styles.thank__title}>Thank You!</h2>
            <div className={styles.thank__descr}>Your account was verified successfully</div>
            <Link to={"/"} className={styles.thank__back}>
              Go to main page
            </Link>
          </div>
          <div className={styles.thank__right}>
            <img data-aos="fade-up" data-aos-duration="1000" src={thank} alt="thank" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankRegister;
