import React, { memo } from "react";

const Check = memo(({ color = "#ffffff" }) => {
  return (
    <svg
      style={{ minWidth: "25px" }}
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1422 14.799L24.0416 4.89949L19.799 0.656854L9.89951 10.5563L4.24266 4.89949L1.54972e-05 9.14214L5.65687 14.799L9.89951 19.0416L14.1422 14.799Z"
        fill={color}
      />
    </svg>
  );
});

export default Check;
