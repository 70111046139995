import React from "react";
import styles from "./About.module.scss";
import "../../common.css";
import aboutMain from "../../img/about1.png";
import about1 from "../../img/about2.png";
import about2 from "../../img/about3.png";
import about3 from "../../img/about4.png";
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation(); 
  return (
    <section className={styles.about}>
      <div className="container">
        <h2 className={styles.about__title}>{t("About Us")}</h2>
        <div className={styles.about__wrapper}>
          <img className={styles.about__mainImage} src={aboutMain} alt="about" />
          <div className={styles.about__info}>
            <div className={styles.about__subtitle}>
            {t("Our")} <span>{t("Vision")}</span>
            </div>
            <div className={styles.about__text}>
              {t("LabAuthentity.org is a project of the crypto startup CeBioLabs and pursues the vision to confirm and guarantee the authenticity of certificates of analysis (laboratory analyses). It happens again and again that the results of laboratory analyses are falsified and manipulated by producers and traders. This deceives end customers in the food, pharmaceutical and chemical industries and exposes them to risk.")}
              <br />
              <br />
              {t("We would like to solve this problem with our unique system and offer market participants from all over the world the possibility to integrate the analysis certificates of their products directly by the laboratories into our portal, thus guaranteeing the authenticity of the documents by storing them in the blockchain. Through blockchain technology, all stored data is immutable and transparent to all market participants and can be retrieved at any time. LabAuthentiy.org is internationally oriented and supports all common certificates of analysis. In addition to certificates of analysis for CBD and cannabis products, our service also supports analyses from the food, pharmaceutical and chemical industries.")}
              <br />
              <br />
              {t("All certificates of analysis available on the Lab-Authenticity.org platform are 100% genuine and guarantee the quality of the lab results. We receive all laboratory analyses directly from the laboratories themselves, which de facto excludes falsification.")}
            </div>
          </div>
        </div>
        <div className={styles.about__wrapper}>
          <div className={styles.about__info}>
            <div className={styles.about__subtitle}>
            {t("Part of")} <span>CeBioLabs</span>
            </div>
            <div className={styles.about__text}>
              {t("LabAuthentity.org is a project of German crypto startup CeBioLabs and is the company's first blockchain-based system. CeBioLabs (represented by CeBiol Blockchain Solutions GmbH) is an association of three German companies with many years of experience in cannabis, CBD and blockchain technology. The company develops blockchain-based solutions for the global emerging CBD and cannabis market. The company's proprietary CBSL token plays an important role in the company's ecosystem. It is the link between all products and serves as a means of payment for the user fees of B2B solutions and services. Thus, the CBSL token is also accepted as a means of payment for LabAuthentity.org. All revenues in US dollars are converted into CBSL tokens after deduction of statutory taxes and 'locked' according to the lock-up period defined in the whitepaper.")}
            </div>
          </div>
          <div className={styles.about__images}>
            <img src={about1} alt="about" />
            <div className={styles.about__info_wrapper}>
              <img src={about2} alt="about" />
              <img src={about3} alt="about" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
