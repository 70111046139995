import axios from "axios"
import { API_URL } from "../http"

const getRequest = (url) => {
  return axios({
    method: 'get',
    url: `${API_URL}/${url}`
  })
}

export default getRequest