import React from 'react';
import styles from './SuccessfullyRegistred.module.scss';
import "../../common.css";
import check from '../../img/checkCircleBig.svg'
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import cn from 'classnames';

const SuccessfullyRegistred = () => {
  return (
    <section className={styles.register}>
      <div className={cn(styles.register__wrapper, 'container')}>
        <div className={styles.register__block}>
          <div>
            <div className={styles.register__left}>
              <img className={styles.register__block_icon} src={check} alt="check" />
              <div className={styles.register__title}>
                Password changed succesfully!
              </div>
              <Link to="/">
                <Button 
                  width = '111px'
                  height = '43px'
                  text="Main page"
                  color="blueWhite"
                />
              </Link>
            </div>
          </div>
        </div>  
      </div>
    </section>
  )
}

export default SuccessfullyRegistred;