import React, { useState, useEffect } from 'react'
import styles from './ChangePassModal.module.scss'
import CloseIcon from '../../../../img/imageComponents/CloseIcon'
import Input from '../../../../Components/Input/Input'
import axios from 'axios'
import checkPassword from '../../../../helpers/checkPassword'
import cn from 'classnames'

const ChangePassModal = ({ closeAction }) => {
  const [oldPass, setOldPass] = useState("");
  const [errorOldPass, setErrorOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [errorRepeatPass, setErrorRepeatPass] = useState("");
  const [isAllowSubmit, setIsAllowSubmit] = useState(false);


  const checkOldPass = (value)  => {
    setOldPass(value.trim());
    setErrorOldPass('');
    checkPassword(value, setErrorOldPass);
  } 

  const checkNewPass = (value)  => {
    setNewPass(value.trim());
    setErrorPass('');
    checkPassword(value, setErrorPass);
  } 

  const checkPassAgain = (value) => {
    setRepeatPass(value.trim())
    setErrorRepeatPass('')
    if(value !== newPass) setErrorRepeatPass('Passwords must match')
  } 


  useEffect(() => {
    if(
      (oldPass !== '' && !errorOldPass.length) && 
      (newPass !== '' && !errorPass.length) && 
      (repeatPass !== '' && !errorRepeatPass.length) 
    ){
      setIsAllowSubmit(true);
    }else{
      setIsAllowSubmit(false);
    }
  }, [oldPass, errorOldPass, newPass, errorPass, repeatPass, errorRepeatPass])

  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        <button 
          className={styles.modal__close}
          onClick={() => closeAction(prev => !prev)}
        >
          <CloseIcon />
        </button>
        <div className={styles.modal__title}>Change Password</div>
        <form className={styles.modal__form}>
          <Input 
            placeholder="***************"
            label="Current password"
            type='password'
            value={oldPass}
            onChange={checkOldPass}
            error={errorOldPass}
          />
          <Input 
            placeholder="***************"
            label="New password"
            type='password'
            value={newPass}
            onChange={checkNewPass}
            error={errorPass}
          />
          <Input 
            placeholder="***************"
            label="Confirm new password"
            type='password'
            value={repeatPass}
            onChange={checkPassAgain}
            error={errorRepeatPass}
          />
          <button 
            className={cn(styles.modal__button, !isAllowSubmit ? styles.modal__button_disabled : '')}
            onClick={() => closeAction(prev => !prev)}
          >
            Save password
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChangePassModal;