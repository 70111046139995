import React, { useMemo } from 'react'
import styles from './Status.module.scss';
import { useTranslation } from 'react-i18next';

const Status = ({text}) => {
  const { t } = useTranslation(); 

  const {color, background} = useMemo(() => {
    switch(text){
      case 'in_process':
        return {color: '#E47B00', background: '#FFF1D7'}
      case 'live':
        return {color: '#00861E', background: '#93DFA4'}
      case 'cancelled':
        return {color: '#CE0000', background: '#EFA5A6'}
      case 'draft':
        return {color: '#2E2E2E', background: '#E5EEF8'}
      case 'paid':
        return {color: '#2E2E2E', background: '#93DFD6'}
      case 'unpaid':
        return {color: '#E47B00', background: '#FFF1D7'}
      case 'valid':
        return {color: '#00861E', background: '#93DFA4'}
      case 'Draft':
        return {color: '#2E2E2E', background: '#E5EEF8'}
      case 'Entwurf':
        return {color: '#2E2E2E', background: '#E5EEF8'}
      case 'Paid':
        return {color: '#2E2E2E', background: '#93DFD6'}
      case "Bezahlt":
        return {color: '#2E2E2E', background: '#93DFD6'}
      case 'Unpaid':
        return {color: '#E47B00', background: '#FFF1D7'}
      case "Unbezahlt":
        return {color: '#E47B00', background: '#FFF1D7'}
      case 'In Process':
        return {color: '#E47B00', background: '#FFF1D7'}
      case 'In Arbeit':
        return {color: '#E47B00', background: '#FFF1D7'}
      case 'Live':
        return {color: '#00861E', background: '#93DFA4'}
      case 'Cancelled':
        return {color: '#CE0000', background: '#EFA5A6'}
      case 'Abgerbochen':
        return {color: '#CE0000', background: '#EFA5A6'}
      case 'Abgebrochen':
        return {color: '#CE0000', background: '#EFA5A6'}
      case 'Abgesagt':
        return {color: '#CE0000', background: '#EFA5A6'}
      default:
        return {color: '#ffffff', background: '#000000'}
    }
  }, [text])

  return (
    <div className={styles.status} style={{color, background}}>
      {
        (text === 'in_process') ?  
        <>
          {t("In process")}
        </>
        :
        <>
          {t(text)}
        </>
      }
    </div>
  )
}

export default Status;