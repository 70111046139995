import React, { memo } from "react";

const CertificatIcon = memo(({ color = "#1C475C" }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11V7.7C8 7.11652 8.23705 6.55695 8.65901 6.14436C9.08097 5.73178 9.65326 5.5 10.25 5.5H19.8125L26 11.55V25.3C26 25.8835 25.7629 26.4431 25.341 26.8556C24.919 27.2682 24.3467 27.5 23.75 27.5H17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 5.5V11.5H26"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 21.5C9.39397 21.5 9.78407 21.4224 10.1481 21.2716C10.512 21.1209 10.8427 20.8999 11.1213 20.6213C11.3999 20.3427 11.6209 20.012 11.7716 19.6481C11.9224 19.2841 12 18.894 12 18.5C12 18.106 11.9224 17.7159 11.7716 17.3519C11.6209 16.988 11.3999 16.6573 11.1213 16.3787C10.8427 16.1001 10.512 15.8791 10.1481 15.7284C9.78407 15.5776 9.39397 15.5 9 15.5C8.20435 15.5 7.44129 15.8161 6.87868 16.3787C6.31607 16.9413 6 17.7044 6 18.5C6 19.2956 6.31607 20.0587 6.87868 20.6213C7.44129 21.1839 8.20435 21.5 9 21.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 21.5L12 27.5L9 26.4091L6 27.5L7 21.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default CertificatIcon;
