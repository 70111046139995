import React from 'react'
import { useTranslation } from 'react-i18next';
import styles from './Button.module.scss';

const Button = (props) => {
  const { t } = useTranslation(); 

  const {
    width = 'auto',
    height = 'auto',
    text,
    color,
    action,
    img = null
  } = props;

  return (
    <button 
      style={{ width: width, height: height }} 
      className={`${styles.button} ${styles[color]}`}
      onClick={action}
    >
      {
        img && <img src={img} alt='icon' />
      }
      {t(text)}
    </button>
  )
}

export default Button;