import React from 'react'
import styles from './MakeNotification.module.scss';

const MakeNotification = (icon, colorTheme, displayName) => {

  const NotificationBase = ({ title, message }) => {

    return(
      <div className={`${styles.noty} ${styles[colorTheme]}`}>
        <img src={icon} alt='icon' />
        <div className={styles.noty__info}>
          <div className={styles.noty__title}>{title}</div>
          <div className={styles.noty__descr}>{message}</div>
        </div>
      </div>
    )
  }

  NotificationBase.displayName = displayName;
  return NotificationBase
}


export default MakeNotification;