import React from 'react'
import styles from "./DatePicker.module.scss"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import CalendarIcon from "../../img/imageComponents/CalendarIcon";
import { useTranslation } from "react-i18next";
import { German } from 'flatpickr/dist/l10n/de.js';
import { english } from 'flatpickr/dist/l10n/default.js';


const DatePicker = ({ value, datePickAction, placeholder, title, lang }) => {
  const { t } = useTranslation();

  let options;
  if(lang === 'de'){
    options = {
      locale: {
        ...German,
        months: {
          ...German.months,
        }
      },
    };
  }else{
    options = {
      locale: {
        ...english,
        months: {
          ...english.months,
        }
      },
    };
  }

  return (
    <label className={styles.date}>
      <span className={styles.span}>
        {t(title)}
      </span>
      <div className={styles.calendarIcon}>
        <CalendarIcon />
      </div>
      <Flatpickr
          value={value}
          onChange={(date) => {
            datePickAction(new Date(date).getTime());
          }}
          className={styles.datePicker}
          placeholder={placeholder}
          options={options}
        />
    </label>
  )
}

export default DatePicker;