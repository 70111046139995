import React, { memo } from "react";

const UserIcon = memo(({ color = "#1C475C" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 5C12.145 5 9 8.145 9 12C9 14.41 10.23 16.55 12.094 17.813C8.527 19.343 6 22.883 6 27H8C8 22.57 11.57 19 16 19C20.43 19 24 22.57 24 27H26C26 22.883 23.473 19.344 19.906 17.812C20.8573 17.1698 21.6368 16.3042 22.1762 15.291C22.7156 14.2778 22.9984 13.1478 23 12C23 8.145 19.855 5 16 5ZM16 7C18.773 7 21 9.227 21 12C21 14.773 18.773 17 16 17C13.227 17 11 14.773 11 12C11 9.227 13.227 7 16 7Z"
        fill={color}
      />
    </svg>
  );
});

export default UserIcon;
