import React, { useState, useEffect, useContext } from 'react'
import styles from './Contact.module.scss'
import '../../../common.css'
import BackIcon from '../../../img/imageComponents/BackIcon'
import { useTranslation } from 'react-i18next';
import checkIcon from '../../../img/checkCircle.svg';
import { regEmail } from '../../../helpers/regexp'
import { API_URL } from '../../../http';
import axios from 'axios';
import { Context } from '../../..';

const Contact = () => {
  const { t } = useTranslation(); 
  const [isModal, setIsModal] = useState(false);
  const [allowSumbmit, setAllowSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [message, setMessage] = useState('');
  const {store} = useContext(Context);

  const checkEmail = (value) => {
    setEmail(value.trim());
    setErrorEmail('')
    if(!value.length) setErrorEmail("Email cannot be empty")
    if(!regEmail.test(value)) setErrorEmail("Enter correct email")
  }

  const postForm = () => {
    const data = { 
      email,
      message
    }
    axios({
      method: 'post',
      url: `${API_URL}/email/contact-form`,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      data
    })
    .then(response => {
      setIsModal(true);
    })
    .catch(error => {
      if(error.response.status === 401){
        store.logout();
      }
    })
  }


  useEffect(() => {
    if(email.length && message.length && !errorEmail.length){
      setAllowSubmit(true)      
    }else{
      setAllowSubmit(false)      
    }
  }, [email, message])

  useEffect(() => {
    setEmail('');
    setMessage('');
  }, [isModal])

  return (
    <section className={styles.contact}>
      <div className="container">
        <div className={styles.contact__wrapper}>
          <div className={styles.contact__left}>
            <h2 className={styles.contact__title}>{t("Not find an answer?")}</h2>
          </div>
          <div className={styles.contact__form}>
            <div className={styles.contact__form_form}>
              <label>
                <span>{t("Your email")}</span>
                <input 
                  placeholder={t("type here")}
                  type="text" 
                  value={email}
                  onChange={(e) => checkEmail(e.target.value)}
                />
                {
                  errorEmail.length
                  ? 
                  <div className={styles.contact__error}>{errorEmail}</div>
                  :
                  null
                }
              </label>
              <label>
                <span>{t("Message")}</span>
                <textarea 
                  placeholder={t("type here")}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </label>
              <button 
                className={!allowSumbmit ? styles.contact__button_disabled : ''}
                onClick={() => postForm()}
              >
                <BackIcon />
                <div>{t("Send")}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {
        isModal ? 
        <div className={styles.contact__modal}>
          <div className={styles.contact__modal_inner}>
            <button 
              className={styles.contact__modal_close}
              onClick={() => setIsModal(false)}
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 5L5 15" stroke="#0091D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M5 5L15 15" stroke="#0091D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <img 
              className={styles.contact__modal_image} 
              src={checkIcon} 
              alt="check" 
            />
            <div className={styles.contact__modal_title}>{t("Message sent")}</div> 
          </div>
        </div>
        :
        null
      }
    </section>
  )
}

export default Contact;