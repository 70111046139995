import React, { memo } from "react";

const EditIcon = memo(({ color = "#0091D2", width = "20px", height="21px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16666 3.83331H3.33332C2.8913 3.83331 2.46737 4.00891 2.15481 4.32147C1.84225 4.63403 1.66666 5.05795 1.66666 5.49998V17.1666C1.66666 17.6087 1.84225 18.0326 2.15481 18.3452C2.46737 18.6577 2.8913 18.8333 3.33332 18.8333H15C15.442 18.8333 15.8659 18.6577 16.1785 18.3452C16.4911 18.0326 16.6667 17.6087 16.6667 17.1666V11.3333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4167 2.58332C15.7482 2.2518 16.1978 2.06555 16.6667 2.06555C17.1355 2.06555 17.5851 2.2518 17.9167 2.58332C18.2482 2.91484 18.4344 3.36448 18.4344 3.83332C18.4344 4.30216 18.2482 4.7518 17.9167 5.08332L9.99999 13L6.66666 13.8333L7.49999 10.5L15.4167 2.58332Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default EditIcon;
