import React from 'react'
import styles from './Aside.module.scss'
import { Link, useLocation} from 'react-router-dom';
import UserIcon from '../../img/imageComponents/UserIcon';
import CertificatIcon from '../../img/imageComponents/CertificatIcon';
import WalletIcon from '../../img/imageComponents/WalletIcon';
import QuestionIcon from '../../img/imageComponents/QuestionIcon';
import { useTranslation } from 'react-i18next';

const Aside = () => {
  const { t } = useTranslation(); 
  return (
    <aside className={styles.aside}>
      <ul className={styles.aside__list}>
        <li>
          <Link className={`${styles.aside__link} ${useLocation().pathname.includes('/certificates') ? styles.aside__link_active : ''}`} to={'/certificates'}>
            <CertificatIcon 
              color={`${useLocation().pathname.includes('/certificates') ? '#FFFFFF' : '#1C475C'}`}
            />
            <div>
            {t("Certificates")}
              </div>
          </Link>
        </li>
        <li>
          <Link className={`${styles.aside__link} ${useLocation().pathname === '/profile' ? styles.aside__link_active : ''}`} to={'/profile'}>
            <UserIcon
              color={`${useLocation().pathname === '/profile' ? '#FFFFFF' : '#1C475C'}`}
            />
            <div>
            {t("My Profile")}
            </div>
          </Link>
        </li>
        <li>
          <Link className={`${styles.aside__link} ${useLocation().pathname.includes('/account-balance') ? styles.aside__link_active : ''}`} to={'/account-balance'}>
            <WalletIcon 
              color={`${useLocation().pathname.includes('/account-balance') ? '#FFFFFF' : '#1C475C'}`}
            />
            <div>
            {t("Account Balance")}
              </div>
          </Link>
        </li>
        <li>
          <Link to={'/faq-profile'} className={`${styles.aside__link} ${useLocation().pathname === '/faq-profile' ? styles.aside__link_active : ''}`}>
            <QuestionIcon color={`${useLocation().pathname.includes('/faq-profile') ? '#FFFFFF' : '#1C475C'}`}/>
            <div>FAQ</div>
          </Link>
        </li>
      </ul>
    </aside>
  )
}

export default Aside;