import styles from './Server.module.scss';
import '../../../../common.css';
import { useTranslation } from 'react-i18next';
import server from '../../../../img/server.png';

const Server = () => {
  const { t } = useTranslation(); 

  return (
    <section className={styles.server}>
      <div className='container'>
        <div className={styles.server__content}>
          <div className={styles.server__wrapper}>
            <div className={styles.server__title}>{t("International")} <span>{t("proof of authenticity for certificates of analysis")}</span></div>
            <div className={styles.server__descr}>{t("Due to its global orientation, our system supports all common certificates of analysis and can store them in the blockchain. Producers from all over the world can include their certificates of analysis by laboratories in our portal and thus guarantee the authenticity of their documents. Our service supports certificates of analysis for CBD and cannabis products as well as analyses from the food, pharmaceutical and chemical industries")}</div>
          </div>
          <img className={styles.server__image} src={server} alt="server" />
        </div>
      </div>
    </section>
  )
}

export default Server;