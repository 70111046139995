import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from "./Partners.module.scss";
import "../../../../common.css";
import bloombergImage from "../../../../img/bloomberg.svg";
import yahooNewsImage from "../../../../img/yahoonews.svg";
import benzingaImage from "../../../../img/benzinga.svg";
import marketwatch from "../../../../img/marketwatch.svg";
import yahooFinanceImage from "../../../../img/yahoofinance.svg";
import cn from 'classnames';

function Partners({lang}) {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={cn(styles.partners, lang == 'de' ? styles.partners__de : "")}>
      <div className="container">
        <div className={styles.partners__wrapper}>
          <a
            href="https://www.bloomberg.com/press-releases/2023-04-13/german-crypto-startup-cebiolabs-launches-blockchain-based-solution-for-authenticity-verification-of-laboratory-analyses"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={bloombergImage} alt="" />
          </a>
          <a
            href="https://www.marketwatch.com/press-release/german-crypto-startup-cebiolabs-launches-blockchain-based-solution-for-authenticity-verification-of-laboratory-analyses-2023-04-13"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={marketwatch} alt="" />
          </a>
          <a
            href="https://www.benzinga.com/pressreleases/23/04/31786193/german-crypto-startup-cebiolabs-launches-blockchain-based-solution-for-authenticity-verification-of"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={benzingaImage} alt="" />
          </a>
          <a
            href="https://news.yahoo.com/german-crypto-startup-cebiolabs-launches-122800935.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={yahooNewsImage} alt="" />
          </a>
          <a
            href="https://finance.yahoo.com/german-crypto-startup-cebiolabs-launches-122800935.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={yahooFinanceImage} alt="" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Partners;
