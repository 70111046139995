import React from 'react'
import styles from './Checkbox.module.scss';
import { useTranslation } from 'react-i18next';

const Checkbox = ({text, value, onChange}) => {
  const { t } = useTranslation(); 

  return (
    <label className={styles.container}>
      <div className={styles.container__text}>{t(text)}</div>
      <input type="checkbox" checked={value} onChange={onChange}/>
      <span className={styles.checkmark}></span>
    </label>
  )
}

export default Checkbox;