import React, { useEffect } from 'react'
import styles from './CanceledPayment.module.scss'
import '../../common.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import thank from '../../img/thank.svg'
import { Link } from 'react-router-dom';


const CanceledPayment = () => {

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <section className={styles.canceled}>
      <div className='container'>
        <div className={styles.canceled__wrapper}>
          <div className={styles.canceled__left}>
            <div className={styles.canceled__title}>We regret to inform you that your payment was unsuccessful, please ensure that you have sufficient funds in your account and that your payment details are correct.</div>
            <Link 
              to="/"
              className={styles.canceled__link}
            >
              To Main Page
            </Link>
          </div>
          <div className={styles.canceled__right}>
            <img data-aos="fade-up" data-aos-duration="1000" src={thank} alt="thank" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CanceledPayment;