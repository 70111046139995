import styles from "./News.module.scss";
import "../../common.css";
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import arrow from "../../img/arrow.svg";

function News() {
  const { t } = useTranslation(); 

  return (
    <section className={styles.news}>
      <div className="container">
        <div className={styles.news__title}>{t("News")}</div>
      </div>
      <div className={styles.news__container}>
        <Swiper
          slidesPerView={1}
          speed={900}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          modules={[Autoplay]}
          breakpoints={{
          576: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2
          },
          991: {
            spaceBetween: 30,
            slidesPerView: 3
          },
          1200: {
            spaceBetween: 50,
            slidesPerView: 3
          },
          }}
        >
          <SwiperSlide>
            <div className={styles.news__wrapper}>
              {/* <div className={styles.news__image}>
                <img src="https://picsum.photos/200/100" alt="picsum" />
              </div> */}
              <div>
                <div className={styles.news__block_title}>
                  {t("Lab-Authenticity.org will be launched Soon")}
                </div>
                <div className={styles.news__descr}>
                  {t("The last adjustments have been made to our platform Lab-Authenticity.org and everything is ready for the launch. All functionalities and payment options as well as the blockchain connection are working without any problems. Currently, we are in talks with numerous partners and will also announce the first partnerships shortly.")}                  
                </div>
                <div className={styles.news__more}>
                  <Link to="/news/nws30104tw" style={{textDecoration: "none"}}>
                    <div className={styles.news__readMore}>
                      <div>{t("read more")}</div>
                      <img src={arrow} alt="arrow" />
                    </div>
                  </Link>
                  <div className={styles.news__data}>
                    06.04.2023 
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.news__wrapper}>
              {/* <div className={styles.news__image}>
                <img src="https://picsum.photos/200/100" alt="picsum" />
              </div> */}
              <div>
                <div className={styles.news__block_title}>
                  {t("Closed beta test Completed")}
                </div>
                <div className={styles.news__descr}>
                  {t("We have now completed the final internal and closed beta testing and only need to make a few minor adjustments to graphics and editorial content. All basic functions are already completed, and the platform is ready for users. The marketing campaign for Lab-Authenticity.org will start soon and will cover numerous channels. We will also work with numerous influencers and start large-scale advertising campaigns.")}
                </div>
                <div className={styles.news__more}>
                  <Link to="/news/nws20104tw" style={{textDecoration: "none"}}>
                    <div className={styles.news__readMore}>
                      <div>{t("read more")}</div>
                      <img src={arrow} alt="arrow" />
                    </div>
                  </Link>
                  <div className={styles.news__data}>
                    05.04.2023
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.news__wrapper}>
              {/* <div className={styles.news__image}>
                <img src="https://picsum.photos/200/100" alt="picsum" />
              </div> */}
              <div>
                <div className={styles.news__block_title}>
                  {t("First partnerships concluded")}
                </div>
                <div className={styles.news__descr}>
                  {t("Lab-Authenticity.org will cover numerous industries and sectors as the world's first platform for the proof of authenticity of certificates of analysis (laboratory analyses). Initially focused on the CBD and cannabis sector, the platform now also includes the food sector and the pharmaceutical industry. As a result, we can form close partnerships in these areas with companies that want to prove the authenticity of their laboratory analyses to their customers or business partners. We have already concluded the first partnerships for this purpose.")}
                </div>
                <div className={styles.news__more}>
                  <Link to="/news/nws10104tw" style={{textDecoration: "none"}}>
                    <div className={styles.news__readMore}>
                      <div>{t("read more")}</div>
                      <img src={arrow} alt="arrow" />
                    </div>
                  </Link>
                  <div className={styles.news__data}>
                    01.04.2023
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default News;
