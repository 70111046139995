import React, { useEffect } from 'react'
import styles from './Faq.module.scss';
import "../../common.css";
import FaqElement from './FaqElement/FaqElement';
import Contact from './Contact/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const faqData = [
  {
    title: "What is Lab-Authenticity.org?",
    descr: "Lab-Authenticity is an independent platform from the German crypto startup CeBioLabs. It offers producers and providers of products the possibility to store certificates of analysis, as a result of laboratory analyses, in the blockchain and make them available to customers in an immutable, transparent and decentralized way.",
    id: 1
  },
  {
    title: "Why is the service needed?",
    descr: "According to statistics, about 30 to 40 percent of all publicly available digital certificates of analysis in the field of CBD and cannabis are fake. This can be applied to other areas as well. This means that suppliers manipulate certificates and change important information in them (e.g., concentration of THC, date of analysis, batch no.) to present their products in a higher quality and to put their products on sale. As a result, end customers and processing actors are no longer checking the quality of the products and are being deceived.",
    id: 2
  },
  {
    title: "How does it work?",
    descr: "The providers and producers (clients of the laboratory analyses) can host their analysis certificates on our platform for a fee and store them in the decentralized database structure of the Polygon blockchain. Very importantly, the providers and producers (clients of the analysis certificates) order us to store the analysis certificates in the blockchain in an immutable, transparent, and decentralized manner. Once they have placed their order, the principals must contact the laboratory that performed the laboratory analyses for them and issued the certificates. After that, the laboratories must send us the original document of the digital analysis certificates in encrypted form, stating the order number of the client, which we automatically record and enter into the blockchain. In this way, we ensure that the original and unmodified analyses are stored directly by the laboratories in the blockchain.",
    id: 3
  },
  {
    title: "Which certificates of analysis are supported?",
    descr: "Our service is aimed at all industries that use certificates of analysis in digital form. In addition to the food, pharmaceutical and chemical industries, Lab-Authenticity.org is also suitable for the following sub-sectors:",
    list: ["Analysis of edible fats, oils, oilseeds, nuts", "Chemical analysis of meat products", "Verification of nutritional values for all products (fat, protein, carbohydrates, vitamins)", "Cosmetic products", "Horticulture (soil analysis, nitrate, and nitrite)", "Composts & Soils", "animal feed", "Drinking water", "Pharmaceuticals"],
    id: 4
  },
  {
    title: "How much does the service cost?",
    descr: "Our service comes at a cost to the client. For each certificate of analysis stored in the blockchain, we assess a fee of $9.95. In return, the client receives the storage of their certificate of analysis in the blockchain, a custom subpage with all the details, the certificate and a link to their website, and the ability to embed our seal on their website.",
    id: 5
  },
  {
    title: "What is the CBSL token, and can I pay with it?",
    descr: "The CBSL token is the token of the German crypto company CeBioLabs. The token serves as Lab-Authenticity.org's central link with the company's many other projects, such as the decentralized marketplace for CBD and cannabis products and the blockchain-based supply chain management system for CBD and cannabis products. All Lab-Authenticity.org sales will be converted into CBSL tokens after taxes and locked according to the period defined in our whitepaper.",
    id: 6
  },
  {
    title: "How can I make the best use of Lab-Authenticity.org as a supplier?",
    descr: "Through Lab-Authenticity.org, suppliers and producers of products can prove the authenticity of the certificates of analysis (laboratory analyses) of their products and store them immutably in the blockchain. An individual subpage on Lab-Authenticity.org certifies the authenticity of each analysis stored in the blockchain. The stores and websites of the providers can link to this subpage and prove the authenticity of their analyses. In addition, providers and manufacturers can also use our quality seals, which they can embed on their websites and stores. The individual subpage with all relevant information and the blockchain hash values is exclusively reserved for Lab-Authenticity.org customers.",
    id: 7
  },
  {
    title: "What are Certificates of Analysis?",
    descr: "A Certificate of Analysis (CoA) is a proof of the ingredients and quality of products used in the food, pharmaceutical and chemical industries. It also verifies that products meet legal or other requirements. These requirements are also considered as quality characteristics, which are proved by the content of ingredients, maximum permissible impurities, physical and pharmaceutical-technical characteristics. Certificates of analysis are usually issued as a digital document and can be found on many websites of suppliers who want to use them to prove the quality of their products.",
    id: 8
  },
  {
    title: "What are the benefits of Lab-Authenticity.org?",
    descr: "Through Lab-Authenticity.org, suppliers can have their digital certificates of analysis issued by laboratories stored as original documents on the blockchain, proving to customers and prospects that the certificates and the results of laboratory analyses based on them are genuine and unaltered.",
    id: 9 
  },
  {
    title: "What is the benefit for me?",
    descr: "Suppliers and producers of products can use our service to prove the authenticity of their laboratory analyses to customers and integrate the proof of authenticity (seal), provided by us, on their website. This enables interested parties to immediately recognize that the results of the laboratory analyses shown on the certificates of analysis are genuine and valid. For customers, this is an important quality indicator, as it allows them to ensure that the products are pure and free of harmful ingredients.",
    id: 10
  },
  {
    title: "What are the advantages of the blockchain?",
    descr: "The Blockchain as a decentralized database structure can store data and content transparently, publicly, and immutably. We use the Blockchain to store certificates of analysis directly from laboratories in the Blockchain to prevent subsequent changes, modifications, or forgeries of these digital documents. Everything stored in the Blockchain is immutable and publicly viewable.",
    id: 11
  },
  {
    title: "What payment options do I have?",
    descr: "Currently, our service can be paid with the payment providers Paypal and Stripe. Payment with the CBSL token is also possible.",
    id: 12
  },
  {
    title: "Can I receive an invoice?",
    descr: "Yes, after successful payment you can download invoices of your transactions in your user area.",
    id: 13
  },
]

const Faq = () => {
  const { t } = useTranslation(); 

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <section className={styles.faq}>
        <div className="container">
          <h2 className={styles.faq__title}>{t("Frequently Asked Questions")}</h2>
          <div className={styles.faq__wrapper}>
            <div
              className={styles.faq__left}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              {
              // Можно ли разделить блоки по другому?
                faqData?.map((item, index) => {
                  const {title, descr, id, list} = item;
                  if(index > 6) return null;
                  return <FaqElement title={t(title)} descr={t(descr)} key={id} list={list} />
                })
              }
            </div>
            <div 
              className={styles.faq__right}
              data-aos="fade-left" 
              data-aos-duration="1000"
            >
              {
                faqData?.map((item, index) => {
                  const {title, descr, id} = item;
                  if(index <= 6) return null;
                  return <FaqElement title={t(title)} descr={t(descr)} key={id} />
                })
              }
            </div> 
          </div>
        </div>
      </section>
      <Contact />
    </>
  )
}

export default Faq;