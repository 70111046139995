import React, { useEffect } from 'react'
import styles from './TransactionCanceled.module.scss'
import '../../common.css'
import Back from '../../Components/Buttons/Back/Back';
import canceledImage from '../../img/canceled.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TransactionCanceled = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={styles.canceled}>
      <div className="container">
        <Back path={"/"} />
        <div className={styles.canceled__wrapper}>
          <div className={styles.canceled__left}>
            <h2 className={styles.canceled__title}>
              Transaction was cancelled
            </h2>
            <div className={styles.canceled__descr}>
              Something went wrong. Try again.
            </div>
          </div>
          <div className={styles.canceled__right}>
            <img data-aos="fade-up" data-aos-duration="1000" src={canceledImage} alt="canceled" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TransactionCanceled;