import React from "react";
import styles from "./Partners.module.scss";
import "../../common.css";
import partner1 from '../../img/partner1.svg';
import partner2 from '../../img/partner2.svg';
import { useTranslation } from 'react-i18next';

const Partners = () => {
  const { t } = useTranslation(); 

  return (
    <section className={styles.partners}>
      <div className="container">
        <div className={styles.partners__header}>
          <h2 className={styles.partners__title}>{t("Partner Laboratories")}</h2>
          <div className={styles.partners__text}>
            {t("We work closely with numerous companies and laboratories that are not only active in CBD and cannabis, but also cover other areas. To increase the reach of our unique service and address producers more directly, we have many close partnerships with laboratories. In addition, these partnerships are intended to increase the level of deployment of our blockchain service and facilitate the transfer of certificates of analysis to us. We count the following laboratories and companies among our partners:")}
          </div>
        </div>
        <div className={styles.partners__wrapper}>
          <div className={styles.partners__block}>
            <div className={styles.partners__block_header}>
              <div>
                <div className={styles.partners__block_title}>BlueCanoby</div>
                <a className={styles.partners__block_link} href="https://bluecanoby.com/">bluecanoby.com/</a>
              </div>
              <img src={partner1} alt="partner" />
            </div>
            <div className={styles.partners__block_text}>
              {t("BlueCanoby is a European Group (Germany, Spain, Switzerland, and Austria) that assists CBD wholesalers with consulting and production services. To do this, the BlueCanoby team relies on an extensive distribution network in the European Union. The team is composed of specialized consultants, doctors, laboratory technicians, scientists, agricultural engineers, and lawyers who are passionate about hemp and dedicated to offering the best products and services. BlueCanoby advises growers, on irrigation, harvesting methods, drying and cultivation system. The BlueCanoby team also takes care of the necessary authorizations, such as the Letter of Intent, through a certified company with all the permits for the commercialization of the product. The daily extraction capacity is up to 40 tons of biomass.")}
            </div>
          </div>
          <div className={styles.partners__block}>
            <div className={styles.partners__block_header}>
              <div>
                <div className={styles.partners__block_title}>Swiss Crystal Lab</div>
                <a className={styles.partners__block_link} href="https://swiss-cristal-lab.ch">swiss-cristal-lab.ch</a>
              </div>
              <img src={partner2} alt="partner" />
            </div>
            <div className={styles.partners__block_text}>
              {t("Swiss Cristal Lab is a company specialized in CBD extraction and analysis, located in Switzerland. All laboratory analysis and protocols performed guarantee an accurate result with the highest quality. Due to the wide range of offers, we can provide competitive prices with the best quality. Our goal is to be a European reference as a wholesaler in the supply of refined oils, crystal, and CBD creams. While we continue to guarantee personalized services to demanding customers and independent distributors. Our offers include Extraction & Distillation, Oils with CBD for Animals or Humans (topical use), Oils with CBD and without THC, CBD capsules, Biomass processing and a huge range of Analysis.")}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
