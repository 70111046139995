import React from "react";
import styles from "./Back.module.scss";
import { Link } from "react-router-dom";
import ArrowIcon from "../../../img/imageComponents/ArrowIcon";

const Back = ({ path, img = true, classname = "" }) => {
  return (
    <Link to={path} className={`${styles.back} ${styles[classname]}`}>
      {img && <ArrowIcon />}
      <div>Back</div>
    </Link>
  );
};

export default Back;
