import styles from './Registration.module.scss';
import '../../../../common.css';
import Button from '../../../../Components/Button/Button';
import banner from '../../../../img/bannerPc.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const Registration = () => {
  const { t } = useTranslation(); 

  return (
    <section className={styles.registration}>
      <div className='container'>
        <div className={styles.registration__banner}>
          <div>
            <div className={styles.registration__title}>{t("100% authentity of lab analysis")}</div>
            <div className={styles.registration__descr}>{t("Unaltered and secure directly from the blockchain!")}</div>
          </div>
          <Link to="/register" style={{textDecoration: "none"}}>
          <Button 
            width='225px'
            height='43px'
            text='Register now for free'
            color='whiteDarkBlue'
          />
          </Link>
          <img className={styles.registration__image} src={banner} alt='banner'/>
        </div>
      </div>
    </section>
  )
}

export default Registration;