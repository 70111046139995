import axios from "axios"
import { API_URL } from "../http"

const postRequest = (url, data) => {
  return axios({
    method: 'post',
    url: `${API_URL}/${url}`,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
    data
  })
}

export default postRequest