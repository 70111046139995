import React, { useEffect, useState, useContext } from 'react'
import styles from './ThankCheckout.module.scss'
import '../../common.css'
import Back from '../../Components/Buttons/Back/Back';
import thank from '../../img/thank.svg'
import AOS from 'aos';
import { useTranslation } from 'react-i18next';
import WarningTriangle from '../../img/imageComponents/WarningTriangle'
import { useParams } from 'react-router-dom';
import 'aos/dist/aos.css';
import axios from 'axios';
import { API_URL } from "../../http";
import { useNavigate } from 'react-router-dom'
import { Context } from '../..';



const ThankCheckout = () => {
  const [amount, setAmount] = useState(null);
  const [idHash, setIdHash] = useState(null);
  const { t } = useTranslation(); 
  const {id, type} = useParams();
  const [pending, setIsPending] = useState('');
  const [amountRequests, setAmountRequests] = useState(0);
  const navigate = useNavigate();
  const {store} = useContext(Context);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_URL}/invoice/id/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        "Accept": "application/json"
      }
    })
    .then(response => {
      console.log(response.data)
      setAmount(response.data?.option?.amount);
      setIsPending(response.data?.status)
      if(response.data?.status !== 'paid'){
        setAmountRequests(prev => prev + 1) 
      }
    })
    .catch(error => {
      if(error.response.status === 401){
        store.logout();
      }
    })
  }, []) 

  useEffect(() => {
    console.log('Try to amount = ', amountRequests);
    const interval = setInterval(() => {
      axios({
        method: 'get',
        url: `${API_URL}/invoice/id/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json',
          "Accept": "application/json"
        }
      })
      .then(response => {
        console.log(response.data)
        setAmount(response.data?.option?.amount)
        setIsPending(response.data?.status)
        if(response.data?.status !== 'paid'){
          if(amountRequests < 50){
            setAmountRequests(prev => prev + 1) 
          }else{
            navigate('/payment-cancelled')
          }
        }
      })
      .catch(error => {
        if(error.response.status === 401){
          store.logout();
        }
      })
    }, 30000);

    return () => {
      clearInterval(interval);
    };

  }, [amountRequests])

  useEffect(() => {
    setAmount(id);
    setIdHash(type);
  }, [id, type])

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={styles.thank}>
      <div className="container">
        <Back path={"/"}/>
        <div className={styles.thank__wrapper}>
          <div className={styles.thank__left}>
          <div className={styles.thank__title}>{t("Thank You!")}</div>
          {
            pending !== "paid" ?
            <div className={styles.thank__pend}>
             <WarningTriangle />
             <div>
              {t("We are currently processing your payment, please do not refresh or close the payment processing page.")}
             </div>
            </div>
            :
            null
          }
          {
            pending !== "paid" ? 
            null
            :
            <>
              <div className={styles.thank__descr}>
                {
                  amount && amount > 1 ?
                  <>
                    {amount} {t("Certificates were added to your account successfully!")}
                  </>
                  :
                  <>
                    {amount} {t("Certificate was added to your account successfully!")}
                  </>
                }
              </div>
              <div className={styles.thank__number}>
                {t("Invoice number:")} {id}
              </div>
            </> 
          }
          </div>
          <div className={styles.thank__right}>
            <img data-aos="fade-up" data-aos-duration="1000" src={thank} alt="thank" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ThankCheckout;