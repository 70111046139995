import React, {useState, useRef, useEffect, useContext} from "react";
import styles from "./Certificate.module.scss";
import "../../common.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import arrow from "../../img/arrowLeft.svg";
import testCertificate from "../../img/certificate.png";
import client from "../../img/clientIpsum.svg";
import check from "../../img/checkStatus.svg";
import copy from "../../img/copy.svg";
import fb from "../../img/blueFb.svg";
import inst from "../../img/blueInst.svg";
import twitter from "../../img/blueTwitter.svg";
import whatsApp from "../../img/blueWats.svg";
import tg from "../../img/blueTg.svg";
import linkedin from "../../img/blueLinkedin.svg";
import blockChain from "../../img/blockchainsquares.svg";
import blockChainBg from "../../img/blockchainBg.svg";
import bitcoin from "../../img/bnb.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import sealEnIcon from '../../img/sealEn.png';
import sealDeIcon from '../../img/sealDe.png';
import printEnIcon from '../../img/printEn.png';
import printDeIcon from '../../img/printDe.png';
import embed from '../../img/embed.svg';
import EyeOpenIcon from '../../img/imageComponents/EyeOpenIcon';
import ShowIcon from '../../img/imageComponents/ShowIcon'
import cn from "classnames";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { API_URL } from "../../http";
import { useParams } from 'react-router-dom';
import { Context } from "../..";

const blockchainInfo = {
  blockchain: "BNB",
  issuer: "A94iDxxJiiPvo8CCWe4GLUfT6BGTWuUg",
  transactionId:
    "",
  name: "2023-12-16, 09:33",
};

const Certificate = ({ lang, user }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [hash, setHash] = useState('');
  const [print, setPrint] = useState('https://lab-authenticity.org/printEn.png');
  const [seal, setSeal] = useState('https://lab-authenticity.org/sealEn.png');
  const [isPublic, setIsPublic] = useState(true);
  const [productCategory, setProductCategory] = useState(null);
  const [dateAnalysis, setDateAnalysis] = useState(null);
  const [batch, setBatch] = useState(null);
  const [productName, setProductName] = useState(null);
  const [expDate, setExpDate] = useState(null);
  const [link, setLink] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [clientWebsite, setClientWebsite] = useState(null);
  const [address, setAddress] = useState(null);
  const [logo, setLogo] = useState(null);
  const { t } = useTranslation(); 
  const {id} = useParams();
  const {store} = useContext(Context);
  const [pdfLinked, setPdfLinked] = useState('');
  const [transactionID, setTransactionID] = useState('')
  const hashInput = useRef(null);
  const sealImage = useRef(null);
  const printImage = useRef(null);
  const [blockNum, setBlockNum] = useState('')

  const handleCopy = (text) => {
    text.current.select();
    document.execCommand('copy');
  }

  const navigate = useNavigate();

  useEffect(() => {
    if(lang === 'de'){
      setPrint('https://lab-authenticity.org/printDe.png');
      setSeal('https://lab-authenticity.org/sealDe.png');
    }else{
      setPrint('https://lab-authenticity.org/printEn.png');
      setSeal('https://lab-authenticity.org/sealEn.png');
    }
  }, [lang])

  useEffect(() => {
    AOS.init();
  }, [])


  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_URL}/certificate/validate/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        "Accept": "application/json"
      }
    })
    .then(response => {
      const {
        batchNumber, 
        analysisDate, expirationDate, laboratoryAddress,
        pdfLink, productCategory, productName,
        publicLink, hash, transaction, block
      } = response?.data

      console.log(response?.data)
      setBlockNum(block)
      setTransactionID(transaction);
      setPdfLinked(pdfLink);
      setHash(hash);
      // const {
        // clientCompany,
        // companyWebsite
      // } = response?.data.user





      setProductCategory(productCategory);
      setDateAnalysis(analysisDate);
      setBatch(batchNumber);
      setProductName(productName);
      setExpDate(expirationDate);
      setLink(publicLink);
      setClientName(response?.data.user.companyName);
      setClientWebsite(response?.data.user.companyWebsite);
      setAddress(laboratoryAddress);
      setLogo(response?.data.user.userLogo)
    })
    .catch(error => {
      if(error.response.status === 401){
        store.logout()
      }
    })
  }, [])
  

  return (
    <>
      <section className={styles.certificate}>
        <div className="container posr">
          <Button
            width="115px"
            height="43px"
            text="Back"
            color="blueBorder"
            img={arrow}
            action={() => navigate(-1)}
          />
          <h2 className={styles.certificate__title}>
            {t("Type of analysis certificate")}
          </h2>
          <div className={styles.certificate__wrapper}>
            <div className={styles.certificate__left_img}>
              <img src={testCertificate} alt="img" />
              <img className={styles.certificate__print} src={lang === 'de' ? printDeIcon : printEnIcon} alt="print" />
              <img className={styles.certificate__seal} src={lang === 'de' ? sealDeIcon : sealEnIcon} alt="seal" />
            </div>
            <div className={styles.certificate__tab}>
              <div onClick={() => setActiveTab(1)} className={activeTab === 1 ? `${styles.certificate__tab_item} ${styles.certificate__tab_item_active}` : `${styles.certificate__tab_item}`}>About</div>
              <div onClick={() => setActiveTab(2)} className={activeTab === 2 ? `${styles.certificate__tab_item} ${styles.certificate__tab_item_active}` : `${styles.certificate__tab_item}`}>Client</div>
            </div>
            <div className={activeTab === 1 ? `${styles.certificate__left_info} ${styles.certificate__tab_active}` : `${styles.certificate__left_info}`}>
              <div className={styles.certificate__status}>
                <img src={check} alt="check" />
                <span>{t("The certificate is valid")}</span>
              </div>
              {
                productCategory ?
                <div className={styles.certificate__row}>
                  <div className={styles.certificate__label}>
                    {t("Product Category")}
                  </div>
                  <div className={styles.certificate__answer}>
                    {productCategory}
                  </div>
                </div>
                :
                null
              }
              {
                dateAnalysis ? 
                <div className={styles.certificate__row}>
                  <div className={styles.certificate__label}>
                    {t("Date of Analysis")}
                  </div>
                  <div className={styles.certificate__answer}>
                    {dateAnalysis}
                  </div>
                </div>
                :
                null
              }
              {
                batch ? 
                <div className={styles.certificate__row}>
                  <div className={styles.certificate__label}>
                    {t("Batch Number")}
                  </div>
                  <div className={styles.certificate__answer}>
                    {batch}
                  </div>
                </div>
                :
                null
              }
              {
                productName ? 
                <div className={styles.certificate__row}>
                  <div className={styles.certificate__label}>
                    {t("Product Name")}
                  </div>
                  <div className={styles.certificate__answer}>
                    {productName}
                  </div>
                </div>
                :
                null
              }
              {
                expDate ? 
                <div className={styles.certificate__row}>
                  <div className={styles.certificate__label}>
                    {t("Expiration Date")}
                  </div>
                  <div className={styles.certificate__answer}>
                    {expDate}
                  </div>
                </div>
                :
                null
              }
            </div>
            <div className={styles.certificate__right_main}>
            <div className={activeTab === 2 ? `${styles.certificate__right} ${styles.certificate__tab_active}` : `${styles.certificate__right}`}>
              <div className={styles.certificate__right_container}>
                {
                  logo ? 
                  <div className={styles.certificate__client_img}>
                    <img src={`${API_URL}/uploads/company-logos/${logo}`} alt="client" />
                  </div>
                  :
                  null
                }
                {
                  clientName ? 
                  <div className={styles.certificate__row}>
                    <div className={styles.certificate__label}>
                      {t("Client ( company )")}
                    </div>
                    <div className={styles.certificate__answer}>
                      {clientName}
                    </div>
                  </div>
                  :
                  null
                }
                {
                  clientWebsite ? 
                  <div className={styles.certificate__row}>
                    <div className={styles.certificate__label}>
                      {t("Website of the company")}
                    </div>
                    <div className={styles.certificate__answer}>
                      {clientWebsite}
                    </div>
                  </div>
                  :
                  null
                }
                {
                  address ? 
                  <div className={styles.certificate__row}>
                    <div className={styles.certificate__label}>
                      {t("Laboratory address")}
                    </div>
                    <div className={styles.certificate__answer}>
                      {address}
                    </div>
                  </div>
                  :
                  null
                }
              </div>
            </div>
            <a href={`https://gateway.pinata.cloud/ipfs/${pdfLinked}`} className={styles.certificate__download} target="_blank" rel="noopener noreferrer">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 2V8H20"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 18V12"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 15L12 18L15 15"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>{t("Download Certificate PDF")}</span>
              </a>
            </div>
          </div>
          <div className={styles.certificate__wrapper_bottom}>
            <div className={styles.certificate__bottom_left}>
              {/* <button className={styles.certificate__download_qr}>
                {t("Download QR Code")}
              </button> */}
            </div>
            <div className={styles.certificate__bottom_right}>
              {/* <div className={styles.certificate__bottom_title}>
                {t("Transaction Hash")}
              </div> */}
              {/* <div className={styles.certificate__hash} onClick={() => handleCopy(hashInput)}>
                <input className={styles.certificate__hash_input} ref={hashInput} onChange={(e) => setHash(e.target.value)} readOnly type="text" value={hash}/>
                <img src={copy} alt="copy" />
              </div> */}
              <div className={styles.certificate__public}>
                {/* <div className={cn(styles.certificate__public_status, isPublic ? styles.certificate__public_open : styles.certificate__public_close)}>
                  {isPublic ? <EyeOpenIcon /> : <ShowIcon color="#EA5455"/>}
                  {
                    isPublic ? 
                    <div>This certificate is public view available</div>
                    :
                    <div>This certificate is unpublish</div>
                  }
                </div> */}
                {/* <button
                  className={cn(styles.certificate__button, isPublic ? styles.certificate__button_public : styles.certificate__button_hiden)}
                  onClick={() => setIsPublic(prev => !prev)}
                >
                  {isPublic ? <ShowIcon color="#0091D2"/> : <EyeOpenIcon color="#ffffff"/>}
                  {
                    isPublic ? 
                    <div>Unpublish certificate</div>
                    :
                    <div>Publish certificate</div>
                  }
                </button> */}
              </div>
              {/* <div className={styles.certificate__share}>
                {
                  link ? 
                  <div className={styles.certificate__block}>
                    <div className={styles.certificate__block_title}>
                      {t("Public Link")}
                    </div>
                      <a
                        className={styles.certificate__block_link}
                        href="https://www.google.com/"
                      >
                        {link}
                      </a>
                  </div>
                  :
                  null
                }
                <div className={styles.certificate__block}>
                  <div className={styles.certificate__block_title}>
                    {t("Share")}
                  </div>
                  <div className={styles.certificate__block_wrapper}>
                    <a href="https://www.google.com/">
                      <img src={fb} alt="fb" />
                    </a>
                    <a href="https://www.google.com/">
                      <img src={inst} alt="inst" />
                    </a>
                    <a href="https://www.google.com/">
                      <img src={twitter} alt="twitter" />
                    </a>
                    <a href="https://www.google.com/">
                      <img src={whatsApp} alt="whatsApp" />
                    </a>
                    <a href="https://www.google.com/">
                      <img src={tg} alt="tg" />
                    </a>
                    <a href="https://www.google.com/">
                      <img src={linkedin} alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className={styles.info}>
        <div className="container">
          <div className={styles.info__wrapper}>
            <div className={styles.info__left}>
              <div className={styles.info__title}>
                {t("Blockchain technical information")}
              </div>
              <img
                data-aos="fade-right" data-aos-duration="1000"
                className={styles.info__squares}
                src={blockChain}
                alt="blockChain"
              />
              <img
                data-aos="fade-right" data-aos-duration="1000"
                className={styles.info__bg}
                src={blockChainBg}
                alt="blockchain"
              />
            </div>
            <div className={styles.info__right}>
              {
                transactionID && transactionID.length ?
                <div className={styles.info__right_row}>
                <div className={styles.info__right_label}>
                  {t("Transaction")}
                </div>
                <a className={styles.info__right_value} href={`https://testnet.bscscan.com/tx/${transactionID}`} target="_blank" rel="noopener noreferrer">
                  {transactionID}
                </a>
                </div>
                :
                null
              }
              <div className={styles.info__wrap}>
              <div className={styles.info__right_row}>
                <div className={styles.info__right_label}>
                  {t("Blockchain")}
                </div>
                <div className={styles.info__right_value}>
                  <img src={bitcoin} alt="btc"  style={{width: "24px"}}/>
                  {blockchainInfo.blockchain}
                </div>
              </div>
              {
                blockNum && blockNum.length ? 
                <div className={styles.info__right_row}>
                  <div className={styles.info__right_label}>
                    {t("Block")}
                  </div>
                  <a className={styles.info__right_value} href={`https://testnet.bscscan.com/block/${blockNum}`} target="_blank" rel="noopener noreferrer">
                    {blockNum}
                  </a>
                </div>
                :
                null
              }
              </div>
              {
                hash ? 
                <div className={styles.info__right_row}>
                <div className={styles.info__right_label}>
                  {t("Hash")}
                </div>
                <div className={styles.info__right_value}>
                  {hash}
                </div>
                </div>
                : 
                null
              }
              {
                user && 
                <div className={styles.info__embed_wrapper}>
                  <div className={styles.info__embed_block}>
                    {/* <img src={embed} alt="speal" /> */}
                    <div className={styles.info__embed_info}>
                      <div className={styles.info__embed_title}>
                        {t("You can also use the seal on your website")}
                      </div>
                      <div className={styles.info__embed_descr}>
                        {t("Simply copy this piece of HTML code and place it on your website.")}
                      </div>
                      <div className={styles.info__embed_links}>
                        <div className={styles.info__embed_link}>
                          <textarea 
                            ref={printImage}
                            value={print} 
                            onChange={setPrint}
                            readOnly></textarea>
                          <img className={styles.info__embed_image} src={lang === 'de' ? 'https://lab-authenticity.org/printDe.png' : 'https://lab-authenticity.org/printEn.png'} alt="seal" width="100" height="47"/>
                          <img 
                            className={styles.info__embed_copy} 
                            src={copy}
                            alt="copy" 
                            onClick={() => handleCopy(printImage)}
                          />
                        </div>
                        <div className={styles.info__embed_link}>
                          <textarea 
                            ref={sealImage} 
                            value={seal} 
                            onChange={setSeal}
                            readOnly
                          ></textarea>
                          <img className={styles.info__embed_image} src={lang === 'de' ? 'https://lab-authenticity.org/sealDe.png' : 'https://lab-authenticity.org/sealEn.png'} alt="seal" width="70" height="auto"/>
                          <img 
                            className={styles.info__embed_copy} 
                            src={copy} 
                            alt="copy" 
                            onClick={() => handleCopy(sealImage)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Certificate;
