import React, { useEffect, useState, useContext } from 'react'
import styles from './Verification.module.scss';
import '../../common.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ErrorNotification } from '../../Components/Notification/ErrorNotification';
import verificationImage from '../../img/verification.svg';
import searchIcon from '../../img/search.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../http';
import { Context } from '../..';

const errorVerification = {
  title: 'Blockchain transaction ID not found.',
  description: 'Error. Blockchain transaction ID not found. Try another code'
}

const Verification = () => {
  const [certificateValue, setSertificateValue] = useState('');
  const [errorCertificate, setErrorSertificate] = useState(false);
  const { t } = useTranslation(); 
  const navigate = useNavigate();
  const {store} = useContext(Context);

  // const searchCertificate = () => {
  //   if(certificateValue === 'sh256'){
  //     navigate(`/verification/certificate/${certificateValue}`)
  //   }else{
  //     setErrorSertificate(true);
  //   }
  // }

  useEffect(() => {
    if(certificateValue === 'error') setErrorSertificate(true)
    else setErrorSertificate(false)
  }, [certificateValue])

  useEffect(() => {
    AOS.init();
  }, [])

  const checkCertificate = () => {
    axios({
      method: 'get',
      url: `${API_URL}/certificate/validate/${certificateValue}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        "Accept": "application/json"
      }
    })
    .then(response => {
      if(response?.data){
        navigate(`/verification/certificate/${response?.data.id}`)
      }
    })
    .catch(error => {
      setErrorSertificate(true);
      if(error.response.status === 401){
        store.logout();
      }
    })
  }


  return (
    <section className={styles.verification}>
      <div className='container'>
        <div className={styles.verification__wrapper}>
          <div className={styles.verification__left}>
            <h2 className={styles.verification__title}>
              {t("Verification of proof of authenticity")}
            </h2>
            <div className={styles.verification__descr}>
              {t("Verify the validity of your certificate in seconds with our blockchain certificate checker. Simply enter your certificate ID and let us do the rest.")}
            </div>
            <label className={styles.verification__search}>
              <input 
                type="text" 
                placeholder='12345678901234567890123456789012345678901234567890' 
                value={certificateValue}
                onChange={(e) => setSertificateValue(e.target.value)}
              />
              <img 
                className={styles.verification__search_loupe} 
                src={searchIcon} alt="searchIcon" 
                onClick={() => checkCertificate()}
              />
            </label>
            {
              errorCertificate && 
              <div className={styles.verification__noty}>
                <ErrorNotification 
                  title={t(errorVerification.title)}
                  message={t(errorVerification.description)}
                />
              </div>
            }
          </div>
          <div className={styles.verification__right}>
            <img data-aos="fade-up" data-aos-duration="1000" src={verificationImage} alt='verification' />
          </div>
        </div>
      </div>
    </section>
  )
}


export default Verification;