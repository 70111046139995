import React, { memo } from "react";

const WalletIcon = memo(({ color = "#1C475C" }) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.969 4.5C22.8108 4.50389 22.6536 4.52502 22.5 4.563L6.25 8.843C5.60769 9.01305 5.03926 9.38992 4.63258 9.91537C4.22589 10.4408 4.00358 11.0856 4 11.75V25.5C4 27.145 5.355 28.5 7 28.5H25C26.645 28.5 28 27.145 28 25.5V12.5C28 10.855 26.645 9.5 25 9.5H11.625L23 6.5V8.5H25V6.5C25 5.375 24.043 4.484 22.969 4.5ZM7 11.5H25C25.566 11.5 26 11.934 26 12.5V25.5C26 26.066 25.566 26.5 25 26.5H7C6.434 26.5 6 26.066 6 25.5V12.5C6 11.934 6.434 11.5 7 11.5ZM22.5 17.5C22.1022 17.5 21.7206 17.658 21.4393 17.9393C21.158 18.2206 21 18.6022 21 19C21 19.3978 21.158 19.7794 21.4393 20.0607C21.7206 20.342 22.1022 20.5 22.5 20.5C22.8978 20.5 23.2794 20.342 23.5607 20.0607C23.842 19.7794 24 19.3978 24 19C24 18.6022 23.842 18.2206 23.5607 17.9393C23.2794 17.658 22.8978 17.5 22.5 17.5Z"
        fill={color}
      />
    </svg>
  );
});

export default WalletIcon;