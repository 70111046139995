import React, { useEffect, useContext } from 'react'
import styles from './SuccessRegistration.module.scss'
import '../../common.css'
import { Link, useNavigate } from 'react-router-dom'
import successRegistration from '../../img/successRegistration.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Context } from '../../index';
import { useObserver } from 'mobx-react-lite';


const SuccessRegistration = () => {
  const { t } = useTranslation(); 
  const {id} = useParams();
  const {store} = useContext(Context);
  // const navigate = useNavigate();

  // const { isAuth, userId } = useObserver(() => ({
  //   isAuth: store.isAuth,
  //   userId: store.userId
  // }));

  useEffect(() => {
    AOS.init();
  }, [])

  // useEffect(() => {
  //   if(!!isAuth && !!userId){
  //     navigate('/profile')
  //   }
  // }, [isAuth, userId])

  useEffect(() => {
    // store.login(name, pass)
  }, [])

  return (
    <section className={styles.registration}>
      <div className="container">
        <div className={styles.registration__wrapper}>
          <div className={styles.registration__left}>
            <div className={styles.registration__title}>
            {t("Congratulations")}! <br /> {t("Registration confirmed")}!
            </div>
            <Link 
              className={styles.registration__link}
              onClick={() => store.verifyUser(id)}
              to={'/login'}
            >
              {t("Let’s go")}
            </Link>
          </div>
          <div 
            className={styles.registration__right}
            data-aos="fade-left" 
            data-aos-duration="1000"
          >
            <img src={successRegistration} alt="Success Registration" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SuccessRegistration;