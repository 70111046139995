import React, { memo } from "react";

const DropdownArrow = memo(({ color = "#2E2E2E", rotate = '0deg' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{transform: `rotate(${rotate})`, transition: '.2s ease-in-out'}}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

export default DropdownArrow;
