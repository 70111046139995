import axios from "axios"
import { API_URL } from "../http"

const updateImage = (url, name, photo) => {
  const formData = new FormData();
  formData.append('image', photo)
  return axios({
    method: "post",
    url: `${API_URL}${url}`,
    headers: { 
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      "Content-Type": "multipart/form-data" 
    },
    data: formData
  })
}


export default updateImage