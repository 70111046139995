import React, { useState } from 'react'
import styles from './PaymentModal.module.scss'
import CloseIcon from '../../../../img/imageComponents/CloseIcon'
import Timer from '../Timer/Timer'
import SettingsContext from '../SettingsContext/SettingsContext'
import { useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


const PaymentModal = ({ closeAction, invoiceId, price, addressWalet }) => {
  const [paymentStep, setPaymentStep] = useState(1);
  const [workMinutes, setWorkMinutes] = useState(10);
  const [breakMinutes, setBreakMinutes] = useState(0);  
  const navigate = useNavigate();
  const { t } = useTranslation(); 


  return (
    <div className={styles.modal}>
      <div className={styles.modal__inner}>
        <button 
          className={styles.modal__close}
          onClick={() => closeAction(false)}
        >
          <CloseIcon />
        </button>
        <div className={styles.modal__title}>
          {t("Pay")}
        </div>
        {
          paymentStep === 1 && 
          <>
            <div className={styles.modal__qr}>
              <QRCode 
                value={addressWalet}
              />
            </div>
            <div className={styles.modal__noty}>
              {t("Scan the QR code and send ")} 
              {price} 
              {t(" tokens to the wallet below (BNB Blockchain)")}
              </div>
            <div className={styles.modal__noty}>
              {t("Address:")}Address:
            </div>

            <div className={styles.modal__wallet}>
              {addressWalet}
            </div>

            <div className={styles.modal__check}>
              {t("To pay:")} {price} {t("Tokens")}
            </div>
            <Link
              to={`/thank-checkout/${invoiceId}`}
              className={styles.modal__paid}
            >
              {t("I’ve Paid!")}
            </Link>
          </>
        }
        {
          paymentStep === 2 && 
          <>
            <SettingsContext.Provider value={{
              workMinutes,
              breakMinutes,
              setWorkMinutes,
              setBreakMinutes
            }}>
            <Timer />
            </SettingsContext.Provider>
            <div className={styles.modal__noty}>
              Thank you! We are checking the blockchain to see this transaction. It usually takes 5-10 minutes. Please stand by!
            </div>
            <button 
              className={styles.modal__exit}
              onClick={() => closeAction(false)}
            >
              Close
            </button>  
          </>
        }
      </div>
    </div>
  )
}

export default PaymentModal;