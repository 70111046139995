import React from 'react'
import styles from './MyProfile.module.scss';
import Aside from '../../Components/Aside/Aside';
import Profile from './components/Profile/Profile';
import News from '../../Components/News/News';

const MyProfile = ({userName, setUserName, isEditProfilePhoto, setIsEditProfilePhoto}) => {
  return (
    <>
      <div className={styles.wrapper}>
        <Aside />
        <Profile 
          userName={userName} 
          setUserName={setUserName} 
          isEditProfilePhoto={isEditProfilePhoto}
          setIsEditProfilePhoto={setIsEditProfilePhoto}
        />
      </div>
      <News />
    </>
  )
}

export default MyProfile